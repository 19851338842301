<template>
  <div>
    <!-- <loading-button secondary> Pause </loading-button> -->
    <div
      class="hover:underline cursor-pointer flex space-x-1"
      @click.prevent="toggleModal"
    >
      <div>Update</div>
      <div>&rarr;</div>
    </div>

    <modal
      :open="modalVisible"
      :on-close="toggleModal"
      id="update-user-details"
      modal-title="Update User Details"
    >
      <form @submit.prevent="updateUserDetails" class="space-y-4">
        <div class="flex space-x-4 items-center">
          <div class="w-48">
            Name
          </div>
          <div class="flex-grow">
            <input type="text" ref="email" v-model="address.name" />
          </div>
        </div>
        <div class="flex space-x-4 items-center">
          <div class="w-48">
            Email
          </div>
          <div class="flex-grow">
            <input type="text" ref="email" v-model="address.email" />
          </div>
        </div>

        <div
          v-if="hasError"
          class=" bg-red-100 p-4 text-red-400 rounded mb-4 error-msg"
        >
          {{
            errorMessage
              ? errorMessage
              : 'There was an error updating this user address'
          }}. Try again
        </div>
        <loading-button :is-loading="isLoading" :is-enabled="isValid">
          Update</loading-button
        >
      </form>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/common/ui/Modal';
import LoadingButton from '@/components/common/ui/LoadingButton';
import { mapActions, mapGetters } from 'vuex';
import { clone, isEqual } from 'lodash/fp';

export default {
  components: {
    Modal,
    LoadingButton
  },
  data() {
    return {
      address: {},
      originalEmail: null,
      originalName: null,
      isLoading: false,
      modalVisible: false,
      errorMessage: '',
      hasError: false
    };
  },
  computed: {
    isValid() {
      if (
        this.address.email &&
        this.address.name &&
        (!isEqual(this.address.email, this.originalEmail) ||
          !isEqual(this.address.name, this.originalName))
      ) {
        return true;
      } else {
        return false;
      }
    },
    ...mapGetters({
      userProfile: 'getCurrentUserProfile'
    })
  },
  methods: {
    ...mapActions({
      getUser: 'getUser'
    }),
    reset() {
      this.address = {
        address1: this.userProfile.user.addr1,
        address2: this.userProfile.user.addr2,
        address3: this.userProfile.user.addr3,
        postcode: this.userProfile.user.postcode,
        phone: this.userProfile.user.phone,
        email: this.userProfile.user.email,
        name: this.userProfile.user.name
      };
      this.originalEmail = clone(this.address.email);
      this.originalName = clone(this.address.name);
    },
    toggleModal() {
      this.modalVisible = !this.modalVisible;
      if (!this.modalVisible) {
        this.reset();
      }
    },
    updateUserDetails() {
      this.isLoading = true;
      this.hasError = false;
      this.$store
        .dispatch('updateAddress', {
          uid: this.$route.params.id,
          data: this.address
        })
        .then(() => {
          this.getUser(this.$route.params.id).then(() => {
            this.isLoading = false;
            this.toggleModal();
          });
        })
        .catch(err => {
          console.log(err);
          this.isLoading = false;
          this.errorMessage = err;
          this.hasError = true;
        });
    }
  },
  mounted() {
    this.reset();
  }
};
</script>

<style></style>
