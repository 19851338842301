<template>
  <div
    class="rounded p-1 px-2 text-xs font-semibold uppercase"
    :class="statusObj.style"
  >
    {{ statusObj.label }}
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: Object,
      required: true
    }
  },
  computed: {
    statusObj() {
      const obj = { style: "text-green-500 bg-green-100", label: "Active" };
      if (this.status) {
        if (this.status) {
          if (this.status.active) {
            obj.style = "text-green-500 bg-green-100";
            obj.label = "Active";
          } else {
            obj.style = "text-red-500 bg-red-100";
            obj.label = "Cancelled";
          }

          if (this.status.paymentpaused) {
            obj.style = "text-gray-500 bg-gray-200";
            obj.label = "Paused";
          }
        } else {
          if (this.status.isSample) {
            obj.label = "Taster Box";
          }
        }
      }

      return obj;
    }
  }
};
</script>

<style lang="css" scoped></style>
