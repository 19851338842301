<template>
  <div class="flex flex-col">
    <div
      class="font-semibold mb-2 text-left w-full items-center flex-col space-y-4"
    >
      <div class="font-semibold mb-2 text-left space-x-2 items-center flex">
        <div class="flex-grow flex items-center space-x-2">
          <div>{{ isActivated ? 'Free Samples' : 'Paid Samples' }}</div>
          <status-label
            v-if="!isActivated"
            :label="samplesCost"
            tooltip="Customer Pays for Samples"
            color="gray"
          />
        </div>

        <div class="text-right font-normal text-gray-500">
          {{ activeSamples.length }} / 3
        </div>
      </div>
      <div class="flex w-full justify-between items-center">
        <div
          :key="sample.sku"
          class="flex items-center justify-center w-24 h-24"
          v-for="sample in activeSamples"
          :data-tippy-content="sample.name"
        >
          <image-getter
            classes="
              object-cover rounded-full w-full h-full
            "
            :url="`/images/products/updated/${sample.sku}.webp`"
            default="/images/products/awaitingimage.webp"
          />
        </div>
        <div
          class="w-24 h-24 flex items-center justify-center"
          v-for="index in samplesLeft"
          :key="`no-sample-${index}`"
        >
          <div
            class="w-20 h-20 flex items-center justify-center text-gray-400 font-semibold text-xl rounded-full bg-gray-100"
          >
            {{ [3, 2, 1][samplesLeft - index] }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ImageGetter from './ImageGetter.vue';
import tippy from 'tippy.js';
import utils from '@/utils';
import StatusLabel from './ColorLabel.vue';
export default {
  components: { ImageGetter, StatusLabel },
  name: 'UserFreeSamples',
  computed: {
    ...mapGetters({
      activeSamples: 'activeSamplesGetter',
      activeSubscription: 'getCurrentUserSubscription'
    }),
    samplesLeft() {
      return 3 - this.activeSamples.length;
    },
    samplesCost() {
      const cost = this.activeSubscription?.settings?.finalExtras || 0;
      return utils.getDisplayCost(cost, 0, true);
    },
    isActivated() {
      return this.activeSubscription?.areSamplesFree; 
    }
  },
  methods: {
    ...mapActions({
      getSamples: 'getFreeSamples'
    })
  },
  mounted() {
    this.getSamples({ id: this.$route.params.id }).then(() => {
      this.$nextTick(() => {
        tippy('[data-tippy-content]', {
          placement: 'top',
          arrow: false,
          delay: [200, 0],
          touch: 'hold'
        });
      });
    });
  }
};
</script>

<style>
.bg-blend {
  background-blend-mode: multiply, saturation;
}
</style>
