<template>
  <div>
    <switch-toggle v-model="value" @input="setSetting" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SwitchToggle from '@/components/common/ui/SwitchToggle';

export default {
  name: 'UserOptionsToggle',
  props: {
    keyId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      value: false
    };
  },
  components: {
    SwitchToggle
  },
  computed: {
    ...mapGetters({
      userProfile: 'getCurrentUserProfile'
    }),
    settings() {
      return this.userProfile.user.settings;
    }
  },
  methods: {
    setSetting(val) {
      this.$store
        .dispatch('toggleUserOption', {
          uid: this.$route.params.id,
          data: { key: this.keyId, value: String(val) }
        })
        .then(resp => {
          console.log(resp);
        });
    },
    checkValue(value) {
      console.log(value);
      if (value === 'true' || value === 'false') {
        if (value === 'true') {
          return true;
        } else {
          return false;
        }
      }
      return value;
    }
  },

  mounted() {
    this.value = this.settings[this.keyId];
  }
};
</script>

<style></style>
