<template>
  <div>
    <div class="flex flex-col">
      <div class="font-semibold mb-2 text-left  items-center flex ">
        <div class="flex-grow">Trustpilot</div>
        <div
          v-if="isAdmin && !hasReview"
          @click.prevent="toggleModal"
          class="font-normal self-end text-gray-500 hover:text-gray-900  cursor-pointer "
        >
          {{ hasReview ? 'Update' : 'Add' }} &rarr;
        </div>
      </div>

      <div
        class="flex justify-between w-full  items-center py-1"
        v-if="hasReview"
      >
        <review-stars :stars="tpReview.stars" v-if="tpReview" />
        <div
          v-if="hasReview"
          @click.prevent="toggleModal"
          class="font-normal text-gray-500 hover:text-gray-900 cursor-pointer"
        >
          {{ hasReview ? (isAdmin ? 'Update' : 'View') : 'Add' }} &rarr;
        </div>
      </div>
      <div class="flex flex-col" v-else>
        <div class="flex justify-between w-full  items-center py-1">
          <div class="text-gray-600">Status</div>
          <div class="">
            {{ reviewSent ? 'Awaiting review' : 'No review sent' }}
          </div>
        </div>
      </div>
    </div>
    <modal
      :open="modalVisible"
      :on-close="toggleModal"
      id="add-review"
      size="xs"
      :modal-title="
        hasReview
          ? isAdmin
            ? 'Update review'
            : 'Trustpilot review'
          : 'Add review'
      "
    >
      <form @submit.prevent="addReview" class="space-y-6 ">
        <div class="space-y-4">
          <div>
            <review-stars-choose
              :stars="getStars() || 5"
              :editable="isAdmin"
              @update="setStars"
            />
          </div>
          <div class="w-full" v-if="isAdmin">
            <textarea
              v-model="review"
              class="w-full"
              placeholder="What was the review..."
            />
          </div>
          <div v-else>
            {{ review }}
          </div>
        </div>
        <div class=" flex items-center" v-if="isAdmin">
          <loading-button :is-loading="isLoading" :is-enabled="true">
            {{ hasReview ? 'Update' : 'Add' }}
          </loading-button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { cloneDeep, isObject } from 'lodash/fp';
import Modal from '@/components/common/ui/Modal';
import ReviewStars from '@/components/common/ui/ReviewStars';
import ReviewStarsChoose from '@/components/common/ui/ReviewStarsChoose';
import LoadingButton from '@/components/common/ui/LoadingButton';

export default {
  name: 'UserTrustPilot',
  components: {
    Modal,
    LoadingButton,
    ReviewStars,
    ReviewStarsChoose
  },
  data() {
    return {
      isLoading: false,
      modalVisible: false,
      review: '',
      stars: 5,
      link: null
    };
  },
  computed: {
    ...mapGetters({
      user: 'getCurrentUserProfile',
      isAdmin: 'isAdmin'
    }),
    tpReview() {
      return this.user.user.settings?.trustpilotReview;
    },
    reviewSent() {
      return this.tpReview?.sent;
    },
    hasReview() {
      return this.user.user.settings?.trustpilotReview?.stars;
    }
  },
  mounted() {
    this.stars = this.tpReview?.stars || this.stars;
    this.review = this.tpReview?.review;
  },
  methods: {
    getStars() {
      return this.tpReview ? this.tpReview?.stars : 5;
    },
    toggleModal() {
      if (this.modalVisible) {
        this.stars = this.stars;
      }
      this.modalVisible = !this.modalVisible;
    },
    setStars(payload) {
      this.stars = payload;
    },
    addReview() {
      const review = this.user.user.settings?.trustpilotReview
        ? cloneDeep(
            isObject(this.user.user.settings?.trustpilotReview)
              ? this.user.user.settings?.trustpilotReview
              : JSON.parse(this.user.user.settings?.trustpilotReview)
          )
        : { link: null, sent: false };

      review.review = this.review;
      review.stars = this.stars;

      this.$store
        .dispatch('toggleUserSetting', {
          uid: this.$route.params.id,
          data: { key: 'trustpilotReview', value: JSON.stringify(review) }
        })
        .then(() => {
          this.toggleModal();
        });
    }
  }
};
</script>

<style></style>
