<template>
  <div @click="getInvoice" class="cursor-pointer hover:bg-gray-100 w-52 ">
    <div class="p-3 py-3 flex items-center leading-none space-x-2 ">
      <div class="w-5 flex justify-center">
        <font-awesome-icon
          :icon="['fal', 'file-arrow-down']"
          class="text-gray-400 text-lg"
        />
      </div>
      <div>
        Generate Invoice
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'getInvoiceBtn',
  props: {
    orderId: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions({
      getInvoice: 'getInvoice',
      generateInvoice: 'generateInvoice'
    }),
    getInvoice() {
      this.$store
        .dispatch('getInvoice', this.orderId)
        .then(response => {
          this.generateInvoice(response[0])
            .then(() => {})
            .catch(() => {
              this.$emit('generate-error', this.orderId);
            });
        })
        .catch(() => {
          this.$emit('generate-error', this.orderId);
        });
    }
  }
};
</script>

<style></style>
