<template>
  <div class="divide-y space-y-4">
    <div class="space-y-4">
      <div class="flex  items-center">
        <div class="text-gray-500 w-48">Reset password</div>
        <div><user-password-reset :email="userProfile.user.email" /></div>
      </div>
      <hr />
      <div class="flex  items-center">
        <div class="text-gray-500 w-48">Send Review email</div>
        <div><user-review-send /></div>
      </div>
    </div>
    <div class="space-y-4 py-4">
      <div class="font-medium">Settings</div>
      <!-- <div>
        <key-value label="Had Taster Box?" :value="hadTaster" />
        <key-value>
          <div class="flex space-y flex-col-4">
            <div
              class="hover:underline"
              :class="
                !userProfile.subscription.sample
                  ? 'text-indigo-300 pointer-events-none '
                  : ' text-indigo-500 cursor-pointer'
              "
              @click.prevent="resetTaster"
            >
              Reset Taster Box &rarr;
            </div>
          </div>
        </key-value>
      </div> -->
      <!-- <div class="flex  items-center">
        <div class="text-gray-500 w-48">Is Northern Ireland?</div>
        <div><user-settings-toggle key-id="isNi" /></div>
      </div> -->
      <!-- <div class="flex  items-center">
        <div class="text-gray-500 w-48">Dont send bonus food?</div>
        <div><user-settings-toggle key-id="hasNoBonusFood" /></div>
      </div> -->
      <div class="flex  items-center">
        <div class="text-gray-500 w-48">Marketing Emails</div>
        <div><user-options-toggle key-id="marketing" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isNull } from 'lodash/fp';
import UserPasswordReset from '@/components/common/ui/user/UserPasswordReset';
// import UserSettingsToggle from '@/components/common/ui/user/UserSettingsToggle';
// import KeyValue from '@/components/common/ui/KeyValue';
import UserReviewSend from '@/components/common/ui/user//UserReviewSend.vue';
import UserOptionsToggle from './UserOptionsToggle.vue';
export default {
  components: {
    UserPasswordReset,
    // UserSettingsToggle,
    // KeyValue,
    UserReviewSend,
    UserOptionsToggle
  },
  computed: {
    ...mapGetters({
      userProfile: 'getCurrentUserProfile'
    }),
    hadTaster() {
      return isNull(this.userProfile?.subscription?.sample) ? 'No' : 'Yes';
    },
    settings() {
      return this.userProfile.user.settings;
    }
  },
  methods: {
    resetTaster() {
      this.$store
        .dispatch('resetTaster', {
          uid: this.$route.params.id
        })
        .then(() => {
          this.$store.dispatch(
            'getUserCurrentSubscription',
            this.$route.params.id
          );
        });
    }
  }
};
</script>

<style lang="css" scoped></style>
