<template>
  <div>
    <form @submit.prevent="createNote" class="space-y-4">
      <textarea
        v-model="userNote"
        ref="noteInput"
        class="w-full"
        :placeholder="placeholder"
      ></textarea>

      <div class="flex space-x-4">
        <loading-button :is-loading="isLoading" :is-enabled="isValid">
          {{
            note
              ? 'Update'
              : noteAction === 'user'
              ? 'Add note to user'
              : 'Add note to order'
          }}</loading-button
        >
      </div>
    </form>
  </div>
</template>

<script>
import LoadingButton from '@/components/common/ui/LoadingButton';
import { isEmpty } from 'lodash/fp';
import utils from '@/utils';
export default {
  props: {
    endpoint: String,
    placeholder: {
      type: String,
      default: 'Say something...'
    },
    isModal: {
      type: Boolean,
      default: false
    },
    noteType: {
      type: String
    },
    note: {
      type: String
    }
  },
  components: {
    LoadingButton
  },
  data() {
    return {
      isLoading: false,
      userNote: this.note || '',
      noteAction: this.noteType || 'user'
    };
  },
  computed: {
    isValid() {
      return !isEmpty(this.userNote);
    }
  },
  methods: {
    createNote() {
      this.isLoading = true;
      this.$store
        .dispatch('createNote', {
          note: this.userNote,
          endpoint: this.endpoint
        })
        .then(() => {
          this.$emit('added');
          this.userNote = '';
          if (this.isModal) {
            utils.toggleModal();
          }

          this.isLoading = false;
        });
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.noteInput.focus();
    });
  }
};
</script>

<style lang="css" scoped></style>
