var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.links.totalpages != 1)?_c('div',{staticClass:"flex justify-between py-4"},[_c('div',[_vm._v(_vm._s(_vm.currentPage)+" of "+_vm._s(_vm.links.totalpages))]),_c('div',{staticClass:"flex space-x-4"},[_c('button',{staticClass:"w-8 h-8rounded leading-none focus:outline-none",class:{
        'text-gray-200 pointer-events-none': _vm.isStart,
        'hover:bg-gray-100 has-tippy ': !_vm.isStart
      },attrs:{"disabled":_vm.isStart,"data-tippy-content":"Previous"},on:{"click":function($event){return _vm.getPage(_vm.links.previouspage)}}},[_c('font-awesome-icon',{staticClass:"text-lg",attrs:{"icon":['fal', 'arrow-left-long']}})],1),_c('button',{staticClass:"w-8 h-8 rounded leading-none focus:outline-none",class:{
        'text-gray-200 pointer-events-none': _vm.isEnd,
        'hover:bg-gray-100 has-tippy ': !_vm.isEnd
      },attrs:{"tag":"div","disabled":_vm.isEnd,"data-tippy-content":"Next"},on:{"click":function($event){return _vm.getPage(_vm.links.nextpage)}}},[_c('font-awesome-icon',{staticClass:"text-lg",attrs:{"icon":['fal', 'arrow-right-long']}})],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }