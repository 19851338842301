<template>
  <div>
    <!-- <loading-button secondary> Pause </loading-button> -->
    <div
      class="hover:underline cursor-pointer flex space-x-1"
      @click.prevent="toggleModal"
    >
      <div>
        Set Payment Date
      </div>
      <div>&rarr;</div>
    </div>

    <modal
      :open="modalVisible"
      :on-close="toggleModal"
      id="setpaymentdate-subscription"
      size="xxs"
      modal-title="
        Set payment date
      "
    >
      <change-payment-date @updated="toggleModal" />
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/common/ui/Modal';

import ChangePaymentDate from '@/components/common/ui/ChangePaymentDate';

export default {
  components: {
    Modal,
    ChangePaymentDate
  },
  data() {
    return {
      modalVisible: false
    };
  },

  methods: {
    toggleModal() {
      this.modalVisible = !this.modalVisible;
    }
  }
};
</script>

<style></style>
