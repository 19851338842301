<template>
  <div>
    <div
      class="hover:underline  cursor-pointer flex space-x-1"
      @click.prevent="toggleModal"
    >
      <div>Edit details</div>
      <div>&rarr;</div>
    </div>

    <modal
      :open="modalVisible"
      :on-close="toggleModal"
      :id="`cat-details-edit-${position}`"
      modal-title="Edit details"
    >
      <div class="space-y-4">
        <div class="space-y-4">
          <div class=" flex items-center">
            <label class="w-48">Name</label>
            <div class="flex-grow">
              <input
                v-model="catsCopy[position].name"
                required
                ref="name"
                type="text"
                :placeholder="cat.name"
                class="w-full"
              />
            </div>
          </div>
          <div class=" flex items-center">
            <label class="w-48">Gender</label>
            <div class="flex-grow flex items-center relative">
              <select v-model="catsCopy[position].gender" class="w-full">
                <option value="" class="text-gray-400" disabled selected
                  >Select an option</option
                >
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Rather not say</option>
              </select>
              <div class="absolute right-4 pointer-events-none">
                <font-awesome-icon
                  class=" text-gray-400"
                  :icon="['fal', 'chevron-down']"
                />
              </div>
            </div>
          </div>
          <div class=" flex items-center">
            <label class="w-48">Tribe</label>
            <div class="flex-grow">
              <input
                v-model="catsCopy[position].tribe"
                required
                ref="name"
                type="text"
                :placeholder="cat.tribe"
                class="w-full"
              />
            </div>
          </div>
          <div class=" flex items-center">
            <label class="w-48">Age</label>
            <div class="flex-grow">
              <radio-buttons
                :options="['kitten', 'adult']"
                v-model="catsCopy[position].type"
              />
            </div>
          </div>
          <div class=" flex items-center border-t py-4">
            <label class="w-48">Is active?</label>
            <div class="flex-grow flex space-x-2">
              <switch-toggle
                v-model="catsCopy[position].active"
                @input="setCatActiveState"
                :disabled="
                  !subscriptionActive ||
                    (activeCats.length === 1 && catsCopy[position].active)
                "
              />
            </div>
          </div>
        </div>
        <div class="flex space-x-4">
          <form @submit.prevent="updateCat">
            <loading-button
              :is-loading="isLoading"
              :is-enabled="hasChanged"
              label="Update"
            >
            </loading-button>
          </form>
          <form @submit.prevent="toggleModal">
            <loading-button label="Cancel" secondary />
          </form>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/common/ui/Modal';
import { cloneDeep, isEqual, filter } from 'lodash/fp';
import LoadingButton from '@/components/common/ui/LoadingButton';
import SwitchToggle from '@/components/common/ui/SwitchToggle';
import { mapGetters } from 'vuex';
import RadioButtons from '../RadioButtons.vue';

export default {
  components: {
    Modal,
    LoadingButton,
    SwitchToggle,
    RadioButtons
  },
  props: {
    cat: {
      type: Object
    },
    position: {
      type: Number
    },
    cats: {
      type: Array
    }
  },
  data() {
    return {
      isLoading: false,
      modalVisible: false,
      catsCopy: cloneDeep(this.cats)
    };
  },
  computed: {
    isValid() {
      return true;
    },
    activeCats() {
      return filter(['active', true], this.catsCopy);
    },
    hasChanged() {
      return !isEqual(this.cats, this.catsCopy);
    },
    subscriptionActive() {
      return this.subscription.subscription.subscription.active;
    },
    ...mapGetters({
      subscription: 'getCurrentUserProfile'
    })
  },
  methods: {
    toggleModal() {
      this.modalVisible = !this.modalVisible;

      this.catsCopy = cloneDeep(this.cats);
    },
    updateCat() {
      this.isLoading = true;
      this.$store
        .dispatch('updateCatDetails', {
          data: this.catsCopy,
          uid: this.$route.params.id
        })
        .then(() => {
          this.isLoading = false;
          this.toggleModal();
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    setCatActiveState() {
      this.$store
        .dispatch('updateCatSubscription', {
          data: this.catsCopy,
          uid: this.$route.params.id
        })
        .then(() => {
          this.$store.dispatch(
            'getUserCurrentSubscription',
            this.$route.params.id
          );
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style></style>
