var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('key-value',{attrs:{"label":"Subscription ID","value":_vm.userProfile.subscription.subscription.subscriptionid}}),_c('key-value',{attrs:{"label":"Type","value":!_vm.userProfile.subscription.subscription.payday
          ? '28 Day Rolling'
          : 'Fixed Payment Date'}}),(_vm.activeSubscription)?_c('key-value',{attrs:{"label":"Payment System","value":_vm.userProfile.subscription.subscription.newPaymentUser
          ? 'Stripe'
          : 'Stripe (Legacy)'}}):_vm._e(),(_vm.activeSubscription)?_c('key-value',{attrs:{"multiline":"","label":"Delivery"}},[_c('div',{staticClass:"flex space-x-1"},[_c('div',{domProps:{"innerHTML":_vm._s(
            _vm.getDeliveryOption(
              _vm.userProfile.subscription.subscription.deliveryTypeSelected
            )
          )}}),(_vm.hasDeliveryCost)?_c('div',{staticClass:"text-gray-600"},[_vm._v(" - "),_c('span',{class:{
              'line-through text-gray-500': _vm.userProfile.coupons.other
            }},[_vm._v(_vm._s(_vm._f("formatPounds")(_vm.subscriptionPrices.originalDelivery)))]),(_vm.userProfile.coupons.other)?_c('span',[_vm._v(" £0")]):_vm._e()]):_vm._e()])]):_vm._e(),(_vm.isAdmin)?_c('key-value',{staticClass:"mb-6"},[_c('div',{staticClass:"flex space-y flex-col-4"},[_c('user-override-delivery')],1)]):_vm._e(),(_vm.isAdmin)?_c('key-value',{staticClass:"mb-6",attrs:{"label":"Coupons","multiline":""}},[_c('div',{staticClass:"flex space-y flex-col-4"},[_c('add-coupon')],1)]):_vm._e(),_c('key-value',{attrs:{"label":"Last updated","value":_vm._f("formatDate")(_vm.userProfile.subscription.subscription.lastchange,'DD MMMM, YYYY h:mm a')}}),_c('key-value',{attrs:{"label":"Last payment","value":_vm._f("formatDate")(_vm.userProfile.subscription.subscription.lastpaymentdate,'DD MMMM, YYYY')}}),_c('key-value',{attrs:{"label":"Next payment","value":_vm.getNextPaymentDate()}}),(_vm.activeSubscription)?_c('key-value',{attrs:{"multiline":""}},[_c('div',{staticClass:"flex flex-col space-y-1"},[_c('div',{staticClass:"flex space-x-1"},[(_vm.hasCoupon && !_vm.userProfile.coupons.onlyDelivery)?_c('div',{staticClass:"mr-1"},[_c('font-awesome-icon',{staticClass:"text-sm text-indigo-600",attrs:{"icon":['fal', 'ticket-simple']}})],1):_vm._e(),_c('div',{class:{
              'line-through text-gray-500':
                _vm.hasCredit || (_vm.hasCoupon && !_vm.userProfile.coupons.onlyDelivery)
            }},[_vm._v(" "+_vm._s(_vm._f("formatPounds")(_vm.subscriptionPrices.originalSubscription))+" ")]),(
              _vm.hasCoupon && !_vm.hasCredit && !_vm.userProfile.coupons.onlyDelivery
            )?_c('div',[_vm._v(" "+_vm._s(_vm._f("formatPounds")(_vm.subscriptionPrices.finalSubscription))+" ")]):_vm._e(),(_vm.hasCredit)?_c('div',[_vm._v(" "+_vm._s(_vm._f("formatPounds")(_vm.subscriptionPrices.finalSubscription))+" ")]):_vm._e()])])]):_vm._e(),(_vm.isAdmin)?_c('key-value',{staticClass:"mt-4"},[_c('div',{staticClass:"flex space-x-2"},[_c('user-take-payment')],1)]):_vm._e(),(_vm.isAdmin)?_c('key-value',[_c('div',{staticClass:"flex space-y flex-col-4"},[_c('a',{staticClass:"text-indigo-500 hover:underline",attrs:{"href":_vm.userProfile.user.stripeLink,"target":"_blank"}},[_vm._v("View in stripe →")])])]):_vm._e(),_c('div',{staticClass:"mt-2 py-4"},[(
          _vm.isAdmin && _vm.activeSubscription && !_vm.paydaySubscription && !_vm.isOnTrial
        )?_c('key-value',[_c('div',{staticClass:"flex space-x-2"},[_c('user-set-payment-date')],1)]):_vm._e(),(_vm.isAdmin && _vm.activeSubscription)?_c('key-value',[_c('div',{staticClass:"flex space-x-2"},[_c('user-pause-subscription')],1)]):_vm._e(),(_vm.isAdmin)?_c('key-value',[_c('div',{staticClass:"flex space-x-2"},[_c('user-edit-subscription')],1)]):_vm._e(),(_vm.isAdmin && _vm.activeSubscription)?_c('key-value',[_c('div',{staticClass:"flex space-y flex-col-4"},[_c('user-cancel-subscription')],1)]):_vm._e()],1)],1),_c('div',{staticClass:"mb-4 font-semibold mt-8"},[_vm._v(" Cats in subscription ("+_vm._s(_vm.userProfile.subscription.subscription.cats.length)+") ")]),_c('div',{staticClass:"flex flex-col overflow-x-auto pb-4 sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4"},_vm._l((_vm.subscriptionCats),function(cat,index){return _c('div',{key:`cat-${index}`,staticClass:"w-full sm:w-96 flex-none text-left border rounded group relative pb-12"},[_c('div',{staticClass:"flex space-x-4 items-center p-4 pb-0"},[_c('cats-images',{attrs:{"cats":[cat]}}),_c('div',{staticClass:"flex-grow"},[_c('div',{staticClass:"flex justify-between items-center"},[_c('div',{staticClass:"flex items-center space-x-1"},[_c('div',{staticClass:"font-semibold"},[_vm._v(_vm._s(cat.name))])]),_c('div',{staticClass:"rounded inline-flex self-end p-1 px-2 text-xs font-semibold uppercase",class:cat.active
                  ? 'bg-green-100 text-green-400'
                  : 'bg-gray-100 text-gray-400'},[_vm._v(" "+_vm._s(cat.active ? 'Active' : 'Inactive')+" ")])]),_c('div',{staticClass:"text-sm text-gray-400 flex space-x-1"},[_c('div',[_vm._v(_vm._s(_vm._f("startCase")(cat.gender)))]),(_vm.isKitten(cat.type))?_c('div',[_vm._v("(Kitten)")]):_vm._e()])])],1),_c('tabs',{attrs:{"padding":false,"embedded":"2","set-url":false}},[_c('tab',{key:"Subscription",attrs:{"slot":"subscription","selected":true},slot:"subscription"},[_c('div',{staticClass:"p-4 pt-2"},_vm._l((_vm.groupProducts(cat.products)),function(value,key){return _c('div',{key:`cat-${key}`,staticClass:"flex flex-col w-full pb-3"},[_c('div',{staticClass:"text-gray-500 italic uppercase text-xs text-left mt-2 mb-2"},[_vm._v(" "+_vm._s(key)+" ")]),_vm._l((value),function(product){return _c('div',{key:`cat-${product.sku}`,staticClass:"flex w-full justify-between"},[_c('div',[_vm._v(" "+_vm._s(product.name)+" "),(product.foodcategory === 'kitten')?_c('span',[_vm._v("(Kitten)")]):_vm._e()]),_c('div',[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('span',{key:_vm.getQuantity(
                          product.increments,
                          product.quantity,
                          product.foodtype
                        ),staticClass:"text-gray-600 ml-2"},[_vm._v(" "+_vm._s(_vm.getQuantity( product.increments, product.quantity, product.foodtype ))+" ")])])],1)])})],2)}),0),(_vm.isAdmin)?_c('div',{staticClass:"absolute bottom-0 py-4 flex px-4"},[_c('div',{staticClass:"w-full flex flex-col space-y-1"},[_c('user-cat-edit',{attrs:{"cat":cat,"position":index,"cats":_vm.userProfile.subscription.subscription.cats}}),(_vm.userProfile.subscription.subscription.cats.length > 1)?_c('user-cat-remove',{attrs:{"cat":cat,"position":index,"cats":_vm.userProfile.subscription.subscription.cats}}):_vm._e()],1)]):_vm._e()]),_c('tab',{key:"Recommendations",attrs:{"slot":"recommendations"},slot:"recommendations"},[(cat.recommendations.length > 0)?_c('div',{staticClass:"p-4"},_vm._l((_vm.groupProducts(
                cat.recommendations
              )),function(recommendation,index){return _c('div',{key:`recommendation-${index}`,staticClass:"flex flex-col space-y-1"},_vm._l((recommendation),function(product){return _c('div',{key:`product-${product.sku}`,staticClass:"flex items-center space-x-2",attrs:{"data-tippy-content":product.name}},[_c('div',{staticClass:"w-8 h-8"},[_c('img',{staticClass:"object-contain",attrs:{"src":`/images/products/${product.sku}.webp`}})]),_c('div',[_vm._v(" "+_vm._s(product.name)),(product.foodcategory === 'kitten')?_c('span',[_vm._v(" (Kitten)")]):_vm._e()])])}),0)}),0):_c('div',{staticClass:"p-4 py-12 text-center text-gray-500"},[_vm._v(" No recommendations currently ")])])],1)],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }