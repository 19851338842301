<template>
  <div class="">
    <div
      class="hover:underline text-red-500 cursor-pointer flex space-x-1"
      @click.prevent="toggleModal"
    >
      <div>Remove</div>
      <div>&rarr;</div>
    </div>

    <modal
      :open="modalVisible"
      :on-close="toggleModal"
      :id="`cat-remove-${position}`"
      modal-title="Remove cat"
    >
      <div class="space-y-8">
        <div class="space-y-4">
          <div>
            You are about to remove <strong>{{ cat.name }}</strong> from this
            subscription.
          </div>
          <div>This cannot be undone. Are you sure?</div>
        </div>
        <div class="flex space-x-4">
          <form @submit.prevent="removeCat">
            <loading-button
              :is-loading="isLoading"
              :is-enabled="true"
              warning
              label="Yes, remove cat"
            >
            </loading-button>
          </form>
          <form @submit.prevent="toggleModal">
            <loading-button label="Cancel" secondary />
          </form>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/common/ui/Modal';
import { cloneDeep, isEqual, filter } from 'lodash/fp';
import LoadingButton from '@/components/common/ui/LoadingButton';

import { mapGetters } from 'vuex';

export default {
  components: {
    Modal,
    LoadingButton
  },
  props: {
    cat: {
      type: Object
    },
    position: {
      type: Number
    },
    cats: {
      type: Array
    }
  },
  data() {
    return {
      isLoading: false,
      modalVisible: false,
      catsCopy: cloneDeep(this.cats)
    };
  },
  computed: {
    isValid() {
      return true;
    },
    activeCats() {
      return filter(['active', true], this.catsCopy);
    },
    hasChanged() {
      return !isEqual(this.cats, this.catsCopy);
    },
    subscriptionActive() {
      return this.subscription.subscription.subscription.active;
    },
    ...mapGetters({
      subscription: 'getCurrentUserProfile'
    })
  },
  methods: {
    toggleModal() {
      this.modalVisible = !this.modalVisible;

      this.catsCopy = cloneDeep(this.cats);
    },
    removeCat() {
      this.isLoading = true;
      if (!this.cat.active) {
        this.$store
          .dispatch('removeCat', {
            uid: this.$route.params.id,
            cid: this.cat.id
          })
          .then(() => {
            this.$store
              .dispatch('getUserCurrentSubscription', this.$route.params.id)
              .then(() => {
                this.isLoading = false;
                this.toggleModal();
              });
          })
          .catch(() => {
            this.isLoading = false;
          });
      } else {
        this.catsCopy[this.position].active = false;
        this.$store
          .dispatch('updateCatSubscription', {
            data: this.catsCopy,
            uid: this.$route.params.id
          })
          .then(() => {
            this.$store
              .dispatch('removeCat', {
                uid: this.$route.params.id,
                cid: this.cat.id
              })
              .then(() => {
                this.$store
                  .dispatch('getUserCurrentSubscription', this.$route.params.id)
                  .then(() => {
                    this.isLoading = false;
                    this.toggleModal();
                  });
              })
              .catch(() => {
                this.isLoading = false;
                this.catsCopy[this.position].active = true;
                this.$store.dispatch('updateCatSubscription', {
                  data: this.catsCopy,
                  uid: this.$route.params.id
                });
              });
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
    },
    getSubscription() {
      this.$store.dispatch('getUserCurrentSubscription', this.$route.params.id);
    }
  }
};
</script>

<style></style>
