<template>
  <div>
    <!-- <loading-button secondary> Pause </loading-button> -->
    <div
      class="hover:underline cursor-pointer flex space-x-1"
      :class="isOverriden ? 'text-red-500' : ''"
      @click.prevent="toggleModal"
    >
      <div>
        {{ isOverriden ? 'Remove Override' : 'Override' }}
      </div>
      <div>&rarr;</div>
    </div>

    <modal
      :open="modalVisible"
      :on-close="toggleModal"
      id="charge-customer"
      size="xxs"
      modal-title="Override Delivery Courier"
    >
      <div class="space-y-4" v-if="!isOverriden">
        <div class="">
          You are about to overide the customers choosen delivery courier.
        </div>
        <div class="flex items-center space-x-4">
          <div class="relative flex-grow flex items-center">
            <div
              class="pointer-events-none absolute right-0 p-3 pt-3 pr-4 text-lg text-gray-700"
            >
              <font-awesome-icon :icon="['fal', 'chevron-down']" />
            </div>
            <select v-model="selectedCourier" class=" text-base">
              <option
                v-for="courier in couriers"
                :key="`courier-${courier.key}`"
                :value="courier"
                >{{ courier.label }}</option
              >
            </select>
          </div>
        </div>
        <div v-if="selectedCourier">
          This will mean that any future orders will go with
          {{ selectedCourier.label }}. Are you sure?
        </div>
        <div
          v-if="error"
          class=" bg-red-100 p-4 text-red-400 rounded mb-4 error-msg"
        >
          {{ error }}
        </div>

        <form @submit.prevent="overrideDelivery">
          <loading-button
            :is-loading="isLoading"
            is-full
            class="text-center"
            label="Set Delivery Override"
          />
        </form>
      </div>
      <div v-else class="space-y-4">
        <div class="">
          You are about to remove the delivery courier override.
        </div>
        <div>
          This will revert the customers delivery courier back to the system
          default. Are you sure?
        </div>
        <form @submit.prevent="overrideDelivery">
          <loading-button
            :is-loading="isLoading"
            is-full
            warning
            class="text-center"
            label="Remove Override"
          />
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/common/ui/Modal';
import LoadingButton from '@/components/common/ui/LoadingButton';

import { mapGetters } from 'vuex';

export default {
  components: {
    Modal,
    LoadingButton
  },
  data() {
    return {
      couriers: [
        { label: 'DPD', key: 'dpd' },
        { label: 'Yodel', key: 'yodel' },
        { label: 'Amazon', key: 'amazon' }
      ],
      selectedCourier: null,
      modalVisible: false,
      isLoading: false,
      error: null
    };
  },
  computed: {
    ...mapGetters({
      userProfile: 'getCurrentUserProfile'
    }),
    isOverriden() {
      return this.userProfile?.subscription?.subscription?.deliveryTypeOverride;
    }
  },
  methods: {
    toggleModal() {
      this.authenticate = '';
      this.modalVisible = !this.modalVisible;
      if (this.modalVisible) {
        if (this.isOverriden) {
          this.selectedCourier = this.couriers.find(
            courier =>
              courier.key ===
              this.userProfile.subscription.subscription.deliveryTypeOverride
          );
        } else {
          this.selectedCourier = this.couriers.find(
            courier =>
              courier.key ===
              this.userProfile.subscription.subscription.deliveryType.type
          );
        }
      }
    },
    overrideDelivery() {
      this.isLoading = true;
      this.error = null;
      this.$store
        .dispatch('overrideDelivery', {
          uid: this.$route.params.id,
          courier: this.isOverriden ? null : this.selectedCourier.key
        })
        .then(response => {
          this.isLoading = false;
          this.$store.dispatch(
            'getUserCurrentSubscription',
            this.$route.params.id
          );
          this.toggleModal();
          console.log('cr rp', response);
        })
        .catch(error => {
          this.isLoading = false;
          this.error = error.response.data.error.message;
        });
    }
  }
};
</script>

<style></style>
