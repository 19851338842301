<template>
  <div
    class="w-10 h-6 flex items-center bg-gray-200 rounded-full p-1 duration-300 cursor-pointer"
    :class="{
      'bg-indigo-500': value,
      'pointer-events-none opacity-50': disabled
    }"
    :aria-checked="value.toString()"
    @click="toggle"
  >
    <div
      class="bg-white w-4 h-4 rounded-full transform duration-300"
      :class="{ 'translate-x-4': value }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggle() {
      this.$emit('input', !this.value);
    }
  }
};
</script>
