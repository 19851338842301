<template>
  <div>
    <div v-if="hasCoupons" class="space-y-1 pb-4 flex flex-col">
      <div v-for="coupon in activeCoupons" class="" :key="coupon.key">
        <div
          class="text-xs group items-center  space-x-2 inline-flex p-1 px-2 bg-indigo-100 text-indigo-600 rounded cursor-pointer uppercase hover:bg-red-100 hover:text-red-400 "
          @click.stop="remove(coupon.coupon)"
        >
          <font-awesome-icon :icon="['fal', 'ticket-simple']" class="text-sm" />
          <div>{{ coupon.display }}</div>
          <div class="hidden group-hover:flex">
            <font-awesome-icon :icon="['fal', 'trash-can']" class="text-sm" />
          </div>
        </div>
      </div>
    </div>
    <div @click.stop="toggleModal" class="cursor-pointer underline">
      Add coupon &rarr;
    </div>
    <modal
      :open="modalVisible"
      :on-close="toggleModal"
      id="assign-to"
      size="xs"
      modal-title="Choose a coupon"
    >
      <div class="space-y-4 mb-6">
        <div>
          <select-dropdown>
            <select v-model="selectedCoupon">
              <option
                v-for="coupon in coupons"
                :key="coupon.key"
                :value="coupon"
                >{{ coupon.display }}</option
              >
            </select>
          </select-dropdown>
        </div>
        <div
          v-if="selectedCoupon"
          class=" text-sm text-gray-600 px-3 font-mono"
        >
          {{ selectedCoupon.description }}
        </div>
      </div>
      <form @submit.prevent="add">
        <loading-button :is-loading="isLoading" :is-enabled="true">
          Add Coupon</loading-button
        >
      </form>
    </modal>
  </div>
</template>

<script>
import LoadingButton from '@/components/common/ui/LoadingButton';
import { mapActions, mapGetters } from 'vuex';
import Modal from '../Modal.vue';
import SelectDropdown from '../SelectDropdown.vue';

export default {
  components: {
    LoadingButton,
    Modal,
    SelectDropdown
  },
  props: {},
  data() {
    return {
      modalVisible: false,
      selectedCoupon: null,
      isLoading: false,
      coupons: []
    };
  },
  computed: {
    ...mapGetters({
      userProfile: 'getCurrentUserProfile'
    }),
    hasCoupons() {
      return this.userProfile?.subscription?.subscription?.coupons.length > 0;
    },
    activeCoupons() {
      return this.userProfile?.subscription?.subscription?.coupons;
    }
  },
  methods: {
    ...mapActions({
      getCoupons: 'getCoupons',
      addCoupon: 'addCoupon',
      removeCoupon: 'removeCoupon',
      getSubscription: 'getUserCurrentSubscription'
    }),
    toggleModal() {
      this.modalVisible = !this.modalVisible;
    },
    add() {
      this.isLoading = true;
      this.addCoupon({
        uuid: this.$route.params.id,
        subid: this.userProfile.subscription.subscription.subscriptionid,
        coupon: this.selectedCoupon.key
      }).then(() => {
        this.isLoading = false;

        this.getSubscription(this.$route.params.id).then(() => {
          this.toggleModal();
        });
      });
    },
    remove(key) {
      this.removeCoupon({
        subid: this.userProfile.subscription.subscription.subscriptionid,
        uuid: this.$route.params.id,
        coupon: key
      }).then(() => {
        this.isLoading = false;
        this.getSubscription(this.$route.params.id);
      });
    }
  },

  mounted() {
    this.getCoupons().then(response => {
      this.coupons = response;
      this.coupons.forEach(coupon => {
        if (coupon.key.includes('percent')) {
          coupon.name = `${coupon.key.split('_')[1].split('percent')[0]}%`;
        } else {
          coupon.name = coupon.key;
        }
      });
      this.selectedCoupon = response[0];
    });
  }
};
</script>

<style lang="scss"></style>
