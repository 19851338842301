<template>
  <div>
    <dropdown-button is-full camo no-padding placement="top" v-if="hasProducts">
      <template v-slot:button>
        <div class="flex items-center">
          <font-awesome-icon
            :icon="['fal', 'magnifying-glass']"
            class="text-gray-400 text-lg"
          />
          <input
            type="text"
            class="input-camo"
            placeholder="Search for a product to add..."
            v-model="searchTerm"
          /></div
      ></template>
      <div>
        <div
          class="p-2 max-h-96 overflow-y-auto"
          v-if="filteredProducts.length > 0"
        >
          <div
            class="flex space-x-4 pr-3 items-center group hover:bg-gray-100 rounded-xl cursor-pointer p-1"
            v-for="product in filteredProducts"
            :key="product.sku"
            @click="addProduct(product)"
          >
            <div class="w-12 h-12 object-contain rounded-lg overflow-hidden">
              <img
                :src="`/images/products/${utils.getImage(product.sku)}.webp`"
              />
            </div>
            <div class="flex-grow">
              <div>
                {{ product.name }}
                <span v-if="isTaster">({{ product.description }})</span>
                <span v-if="product.foodcategory === 'kitten'">(Kitten)</span>
              </div>
              <div class="font-mono text-xs mt-1 text-gray-400">
                {{ product.sku }}
                <span v-if="!product.hasDryFood && isTaster">(No Dry)</span>
              </div>
            </div>

            <div
              class="w-8 h-8 bg-gray-100 rounded-full flex items-center justify-center group-hover:bg-gray-200"
            >
              <font-awesome-icon
                :icon="['fal', 'plus']"
                class="text-gray-400 text-lg"
              />
            </div>
          </div>
        </div>
        <div v-else class="py-8 text-center text-gray-400">
          No product found
        </div>
      </div>
    </dropdown-button>
  </div>
</template>

<script>
import Fuse from 'fuse.js';
import utils from '@/utils';
import { mapActions, mapGetters } from 'vuex';
import DropdownButton from '../DropdownButton.vue';
export default {
  name: 'AddProductsButton',
  components: {
    DropdownButton
  },
  props: {
    activeProducts: {
      type: Array
    },
    isTaster: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isVisible: false,
      searchTerm: '',
      isLoading: false,
      key: utils.createKey('add-products', 6),
      utils
    };
  },
  computed: {
    ...mapGetters({
      products: 'getFoodProducts',
      tasters: 'getTasterProducts'
    }),
    hasProducts() {
      let list = this.products;
      if (this.isTaster) {
        list = this.tasters;
        list = list.map(item => {
          item.hasDryFood = !item.sku.includes('_NDY_');
          return item;
        });
      }
      // filter out the active products from the list
      const activeProducts = this.activeProducts.map(item => item.sku);
      const filteredList = list.filter(
        item => !activeProducts.includes(item.sku)
      );
      return filteredList.length > 0 ? true : false;
    },
    filteredProducts() {
      let list = this.products;
      if (this.isTaster) {
        list = this.tasters;
        // filter out only available tasters
        list = list.filter(item => item.available);
      }
      // filter out the active products from the list
      const activeProducts = this.activeProducts.map(item => item.sku);
      const filteredList = list.filter(
        item => !activeProducts.includes(item.sku)
      );

      const options = {
        threshold: 0.1,
        keys: ['name', 'sku', 'foodtype']
      };

      const fuse = new Fuse(filteredList, options);

      const result = fuse.search(this.searchTerm);

      if (this.searchTerm.length > 0) {
        // order result alphabetically by name
        result.sort((a, b) => {
          if (a.item.name < b.item.name) {
            return -1;
          }
          if (a.item.name > b.item.name) {
            return 1;
          }
          return 0;
        });

        return this.orderByName(result.map(item => item.item));
      } else {
        return this.orderByName(filteredList);
      }
    }
  },
  methods: {
    ...mapActions({
      createNewOrder: 'createOrder'
    }),
    toggleModal() {
      this.isVisible = !this.isVisible;
    },
    addProduct(product) {
      // if kitten add isKitten
      if (product.foodcategory === 'kitten') {
        product.isKitten = true;
      }

      if (product.foodtype === 'dry') {
        product.isDryFood = true;
      }
      this.$emit('add-product', product);
      this.searchTerm = '';
    },
    orderByName(arr) {
      return arr.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }

        return 0;
      });
    }
  },
  mounted() {}
};
</script>

<style></style>
