<template>
  <div
    :key="service.key"
    class=" text-sm text-gray-400 flex items-center justify-center space-x-4 "
    :class="overrideSize ? 'h-full' : ''"
  >
    <div v-if="!isIcon">{{ service.name }}</div>
    <div :class="getSize(service.type)">
      <img :src="`/images/${service.type}_logo.webp`" style="height: 100%" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShippedWith',
  props: {
    service: {
      type: Object
    },
    isIcon: {
      type: Boolean,
      default: false
    },
    overrideSize: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getSize(type) {
      const sizes = { dpd: 'h-5', rm: 'h-7', yodel: 'h-3', amazon: 'h-4' };

      return this.overrideSize ? 'h-full' : sizes[type];
    }
  }
};
</script>

<style></style>
