<template>
  <div>
    <div
      v-for="(item, key, index) in groupedActivity"
      :key="`item-${index}`"
      class="mb-8"
    >
      <div class="font-semibold mb-3">
        <font-awesome-icon
          :icon="['fal', 'calendar-day']"
          class="text-lg mr-2"
        />{{ key | formatDate('Do MMMM, YYYY') }}
      </div>
      <div>
        <activity-item
          v-for="(activity, i) in item"
          :key="`item-${index}-${i}`"
          :activity="activity"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';
import ActivityItem from '@/components/common/ui/activity/ActivityItem';
import { mapGetters } from 'vuex';

export default {
  components: {
    ActivityItem
  },

  data() {
    return {
      groupedActivity: {}
    };
  },
  methods: {
    formatActivity() {
      const mapper = {
        paymentsuccess: {
          class: 'bg-green-100 text-green-500',
          label: 'Succeeded'
        },
        paymentfail: {
          class: 'text-red-500 bg-red-100',
          label: 'Failed'
        }
      };

      const filterOut = ['cancelquestions', 'paymentsuccess', 'paymentfail'];

      // Create a dateGroup property on each activity item to group by and format the date as yyyy-MM-dd  (e.g. 2019-01-01) filtering out any activity items that are in the filterOut array

      // for each subscriptioncancelled type find the cancelquestions type that comes before it, then get the data attribute from the cancelquestions and merge it with the data on the subscriptioncancelled
      let activity = this.activity.map((item, index, arr) => {
        if (item.type === 'subscriptioncancelled') {
          const found = arr[index - 1];
          if (found) {
            return {
              ...item,
              data: {
                ...item.data,
                reason: found?.data?.data?.stepTwo || 'Unknown'
              }
            };
          }
        }
        if (item.type === 'paymentattempted') {
          const found = this.activity.find(
            i =>
              (i.type === 'paymentsuccess' || i.type === 'paymentfail') &&
              i.data.groupId === item.data.groupId
          );
          if (found) {
            return {
              ...item,
              attempt: { ...found, ...mapper[found.type] }
            };
          }
        }
        return item;
      });

      activity = activity
        .map(item => {
          const date = new Date(item.date);
          const dateGroup = format(date, 'yyyy-MM-dd');
          return {
            ...item,
            dateGroup
          };
        })
        .filter(item => !filterOut.includes(item.type));

      // group by the dateGroup property
      const grouped = activity.reduce((groups, item) => {
        const val = item.dateGroup;
        groups[val] = groups[val] || [];
        groups[val].push(item);
        return groups;
      }, {});

      this.groupedActivity = grouped;
    }
  },
  computed: {
    ...mapGetters({ activity: 'getCurrentUserProfileActivity' })
  },
  watch: {
    activity: {
      setImmediate: false,
      handler(val) {
        if (val) {
          this.formatActivity();
        }
      }
    }
  },
  mounted() {
    if (!this.activity) {
      this.$store
        .dispatch('getUserActivity', this.$route.params.id)
        .then(() => {
          this.formatActivity();
        });
    } else {
      this.formatActivity();
    }
  }
};
</script>

<style lang="css" scoped></style>
