<template>
  <div class="relative overflow-hidden rounded-lg">
    <div class="absolute inset-0 ">
      <div id="map-view" class=" relative h-full w-full "></div>
    </div>
    <div class="absolute bottom-0 z-10 w-full h-64 bg-red-500 map-shadow"></div>

    <div class="relative z-10">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import http from "@/http";
import mapboxgl from "mapbox-gl/dist/mapbox-gl";
export default {
  props: {
    city: String
  },
  data() {
    return {
      accessToken:
        "pk.eyJ1IjoiYmVhc29uIiwiYSI6ImNqaGYyeHUyMTFja24zZGtmNzhxNDZqeHYifQ.nOGLTvPZepuV0OE8FDhQEQ"
    };
  },
  mounted() {
    mapboxgl.accessToken = this.accessToken;

    this.getCoordinates();
  },
  methods: {
    getCoordinates() {
      http
        .get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${
            this.city ? this.city : "united kingdom"
          }.json?country=gb&access_token=${this.accessToken}`
        )
        .then(response => {
          this.renderMap(response.data.features[0].geometry.coordinates);
        })
        .catch(() => {
          this.renderMap();
        });
    },
    renderMap(coordinates) {
      new mapboxgl.Map({
        container: "map-view",
        style: "mapbox://styles/beason/ckgv3yisy3q1019patgx34val",
        center: coordinates,
        zoom: this.city ? 7 : 0
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.map-shadow {
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 78%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 78%
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 78%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
}
</style>
