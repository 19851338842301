<template>
  <div :data-tippy-content="tooltip" :class="key">
    <div
      class="text-xs rounded flex h-6 min-w-max items-center relative uppercase font-semibold"
      :class="[
        `bg-${color}-100 text-${color}-500 `,
        icon ? '' : ' p-1 px-2',
        reverse ? 'flex-row-reverse' : ''
      ]"
    >
      <div v-if="icon" class="flex">
        <div
          v-for="item in icon"
          :key="`icon-${item}`"
          class="text-base h-6 w-6 flex-none relative flex items-center justify-center"
        >
          <div class="absolute flex-none">
            <font-awesome-icon :icon="['fal', item]" />
          </div>
        </div>
      </div>
      <div v-if="label" :class="icon ? (reverse ? 'pl-2 ' : 'pr-2') : ''">
        {{ label }}
      </div>
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js';
import utils from '@/utils';

export default {
  props: {
    label: {
      type: [String, Boolean],
      required: true
    },
    tooltip: {
      type: String
    },
    color: {
      type: String,
      default: 'gray'
    },
    icon: {
      type: Array
    },
    reverse: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tippyInstance: null
    };
  },

  computed: {
    key() {
      const self = this;
      if (this.tooltip) {
        const key = utils.createKey('tooltip', 6);
        self.$nextTick(() => {
          self.tippyInstance = tippy(`.${key}`, {
            placement: 'top',
            arrow: false,
            delay: [400, 0]
          });
        });

        return key;
      }
      return '';
    }
  }
};
</script>

<style lang="css" scoped></style>
