<template>
  <div class=" bg-white rounded-lg ">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.card-section {
  @apply p-0 sm:p-4;
}
</style>
