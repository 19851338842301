<template>
  <div class="flex space-x-1">
    <div class="w-6 " :key="index" v-for="(star, index) in 5">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 96 96"
        style="enable-background:new 0 0 96 96;"
        xml:space="preserve"
      >
        <path
          class="st0"
          :style="`fill: ${getColor(index)}`"
          d="M0,0v96h96V0H0z M48,64.7L62.6,61l6.1,18.8L48,64.7z M60.9,55.4l-12.8,9.2l-20.8,15l7.9-24.2l-20.8-15h25.7
	L48,16.2l7.9,24.2h25.7L60.9,55.4z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: ['stars'],
  data() {
    return {
      color: ['#ff3722', '#ff8622', '#ffce00', '#73cf11', '#00b67a']
    };
  },
  computed: {
    currentFill() {
      return this.color[this.stars - 1];
    },
    currentIndex() {
      return this.stars - 1;
    }
  },
  methods: {
    getColor(index) {
      if (this.currentIndex || this.currentIndex === 0) {
        if (index <= this.currentIndex) {
          return this.currentFill;
        } else {
          return '#dcdce6';
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.star-bg {
  @apply fill-current;
}
</style>
