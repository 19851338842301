<template>
  <div class="py-4 relative flex flex-col sm:flex-row sm:items-center">
    <div class="flex flex-col flex-none w-full sm:w-96">
      <key-value
        label="Order ID"
        :value="`#${item.invId}`"
        :has-link="{
          name: 'packingProfile',
          params: { id: item.dispatchid }
        }"
      />
      <key-value
        label="Order Date"
        :value="item.purchasedAt | formatDate('DD MMMM, YYYY')"
      />
      <key-value :value="item.purchasedAt | formatDate('hh:mm A')" />

      <key-value
        label="Type"
        :value="
          item.currentDetails.customOrder
            ? 'Custom Order'
            : item.type === 'Subscription'
              ? 'Subscription'
              : getTasters(item)
        "
      />
      <key-value>
        <div>
          <div class="flex space-x-1" v-if="item.currentDetails.settings">
            <div
              :class="{
                'line-through text-gray-500':
                  hasCredit || (hasCoupons && !coupons.onlyDelivery)
              }"
            >
              {{
                item.currentDetails.settings.originalSubscription | formatPounds
              }}
            </div>
            <div v-if="hasCredit || (hasCoupons && !coupons.onlyDelivery)">
              {{
                item.currentDetails.settings.finalSubscription | formatPounds
              }}
            </div>
          </div>
          <div class="flex space-x-1" v-else>
            <div
              v-if="item.currentDetails.creditUsed > 0"
              class="line-through text-gray-500"
            >
              {{ item.cost | formatPounds }}
            </div>
            <div>
              <div v-if="checkCharged(item.currentDetails.charged)">
                {{ getCharged(item.currentDetails.charged) }}
              </div>
              <div v-else>
                {{ getCharged(item.cost) }}
              </div>
            </div>
          </div>
          <div v-if="hasCoupons" class="mt-4 space-y-1">
            <div
              v-for="coupon in item.currentDetails.coupons"
              class=""
              :key="coupon.key"
            >
              <div
                class="text-xs group items-center space-x-2 inline-flex p-1 px-2 bg-indigo-100 text-indigo-600 rounded uppercase"
              >
                <font-awesome-icon
                  :icon="['fal', 'ticket-simple']"
                  class="text-sm"
                />
                <div>{{ coupon.display }}</div>
              </div>
            </div>
          </div>
          <div v-if="hasCredit" class="mt-1">
            <div
              class="text-xs group items-center space-x-2 inline-flex p-1 px-2 bg-indigo-100 text-indigo-600 rounded uppercase"
            >
              <font-awesome-icon
                :icon="['fal', 'ticket-simple']"
                class="text-sm"
              />
              <div>
                {{ item.currentDetails.creditUsed | formatPounds }} Credit
              </div>
            </div>
          </div>
        </div>
      </key-value>
      <key-value label="Samples" v-if="hasExtras"
        ><div>
          {{
            item.currentDetails.settings.finalExtras
              ? item.currentDetails.settings.finalExtras
              : '?' | formatPounds
          }}
        </div></key-value
      >
      <key-value label="Delivery" v-if="item.type === 'Subscription'"
        ><div>
          {{
            item.currentDetails.settings
              ? item.currentDetails.settings.finalDelivery
              : '?' | formatPounds
          }}
        </div></key-value
      >
      <key-value label="Total Charged" v-if="item.type === 'Subscription'"
        ><div>
          {{
            item.currentDetails.settings
              ? item.currentDetails.settings.total
              : '?' | formatPounds
          }}
        </div></key-value
      >

      <key-value class="mt-4">
        <div v-if="!item.trackingUrl" class="text-gray-400 italic">
          Tracking unavailable
        </div>
        <div v-else class="flex flex-col space-y-2">
          <shipped-with
            v-if="getActiveService(item.shippingCode)"
            :service="getActiveService(item.shippingCode)"
          />
          <a target="_blank" class="underline" :href="item.trackingUrl"
            >View tracking &rarr;</a
          >
        </div>
      </key-value>
      <key-value
        class="mt-4"
        v-if="item.currentDetails.packingError && isAdmin"
      >
        <div
          class="flex space-x-2 items-center bg-red-100 rounded p-2 py-1 text-gray-700 text-sm"
          :ref="key"
          :info="item.currentDetails.packingError.description"
          :data-template="`issue-${item.dispatchid}`"
        >
          <font-awesome-icon :icon="['fal', 'flag']" class="text-red-400" />
          <div>Issue raised</div>
          <div class="hidden" :id="`issue-${item.dispatchid}`">
            <div class="px-3 py-1 w-64 flex flex-col space-y-2 text-sm">
              <div>
                {{ item.currentDetails.packingError.description }}
              </div>
              <div class="text-sm text-gray-500 flex items-center space-x-2">
                <div>raised by</div>
                <avatar
                  size="xs"
                  :email="item.currentDetails.packingError.raisedBy.email"
                />
                <div>
                  {{ item.currentDetails.packingError.raisedBy.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </key-value>
    </div>
    <div
      class="h-full items-center mt-6 sm:mt-0 flex-grow sm:pl-12"
      v-if="isAdmin"
    >
      <div
        class="flex items-center space-x-2 inline-flex absolute top-5 right-0 leading-none"
      >
        <div
          v-if="hasError === item.dispatchid"
          data-tippy-content="Invoice not available"
        >
          <font-awesome-icon
            :icon="['fad', 'triangle-exclamation']"
            class="text-red-500 text-lg"
          />
        </div>
        <dropdown-button
          class=""
          camo
          no-dropdown
          :width="300"
          placement="bottom-end"
        >
          <template v-slot:button
            ><font-awesome-icon
              :icon="['fal', 'ellipsis']"
              class="text-gray-400 text-lg"
            />
          </template>
          <div class="py-2">
            <generate-invoice-btn
              :order-id="item.dispatchid"
              @generate-error="showError"
            />
            <generate-label-btn
              :endpoint="item.links.downloadLabel"
              :order-id="item.invId"
            />
            <create-order
              label="Duplicate Order"
              :order="item"
              v-if="item.type !== 'Sample pack'"
              :is-taster="item.type === 'Sample pack'"
            />
            <track-order-error-btn
              :item="item"
              :can-raise="canRaiseError(item)"
            />
          </div>
        </dropdown-button>
      </div>
      <history-timeline :history="item" v-if="item.status !== 'cancelled'" />
      <div v-else class="bg-red-100 text-center p-4 rounded text-red-500">
        Order has been cancelled.
      </div>
    </div>
  </div>
</template>

<script>
import KeyValue from '@/components/common/ui/KeyValue';
import { isNumber, isEmpty } from 'lodash/fp';
import HistoryTimeline from '@/components/common/ui/order/HistoryTimeline';
import ShippedWith from '@/components/common/ui/ShippedWith';
import { mapGetters } from 'vuex';
import GenerateInvoiceBtn from './GenerateInvoiceBtn.vue';
import DropdownButton from '../DropdownButton.vue';
import tippy from 'tippy.js';
import utils from '@/utils';
import CreateOrder from './CreateOrder.vue';
import TrackOrderErrorBtn from './TrackOrderErrorBtn.vue';
import Avatar from '../Avatar.vue';
import { isAfter } from 'date-fns';
import GenerateLabelBtn from './GenerateLabelBtn.vue';

export default {
  name: 'HistoryItem',
  components: {
    KeyValue,
    HistoryTimeline,
    ShippedWith,
    GenerateInvoiceBtn,
    DropdownButton,
    CreateOrder,
    TrackOrderErrorBtn,
    Avatar,
    GenerateLabelBtn
  },

  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {
      hasError: false,
      key: utils.createKey('history-item', 6)
    };
  },
  computed: {
    ...mapGetters({
      services: 'deliveryServicesGetter',
      isAdmin: 'isAdmin'
    }),
    coupons() {
      if (this.hasCoupons) {
        return utils.formatCoupons(this.item.currentDetails.coupons);
      }
      return null;
    },
    hasCoupons() {
      return !isEmpty(this.item.currentDetails.coupons);
    },
    hasCredit() {
      return this.item.currentDetails.creditUsed > 0;
    },
    hasExtras() {
      return (
        this.item?.currentDetails?.settings &&
        this.item?.currentDetails?.settings?.finalExtras > 0
      );
    }
  },
  methods: {
    getCharged(value) {
      return isNumber(value) ? this.formatValue(value) : '£?';
    },
    checkCharged(value) {
      return isNumber(value);
    },
    formatValue(val) {
      const pounds = val / 100;
      return `£${pounds.toLocaleString(undefined, {
        minmumSignificantDigits: 2
      })}`;
    },
    getActiveService(item) {
      return this.services['all'].find(s => s.key === item) || null;
    },
    getTasters(item) {
      const counts = {
        adult: 0,
        kitten: 0
      };
      const tasters = [];
      item.cats.forEach(c => {
        c.isKitten ? counts.kitten++ : counts.adult++;
      });
      if (counts.adult !== 0) {
        tasters.push(`${counts.adult} x Taster Box`);
      }
      if (counts.kitten !== 0) {
        tasters.push(`${counts.kitten} x Taster Box (Kitten)`);
      }

      return tasters;
    },
    showError(val) {
      this.hasError = val;
      this.$nextTick(() => {
        tippy('[data-tippy-content]', {
          placement: 'left',
          arrow: false,
          maxWidth: 300
        });
      });
    },
    canRaiseError(item) {
      const dateStart = new Date(2024, 0, 20);

      const packedAt = new Date(item.packedAt);

      return isAfter(packedAt, dateStart);
    }
  },
  mounted() {
    this.$nextTick(() => {
      tippy(this.$refs[this.key], {
        content(reference) {
          const id = reference.getAttribute('data-template');
          const template = document.getElementById(id);
          return template.innerHTML;
        },
        arrow: false,
        theme: 'light',
        allowHTML: true
      });
    });
  }
};
</script>

<style lang="css" scoped></style>
