<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    selected: { default: false }
  },

  data() {
    return {
      isActive: false
    };
  },
  mounted() {
    this.isActive = this.selected;
  }
};
</script>

<style lang="css" scoped></style>
