var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex py-1",class:{
    'w-full': _vm.isFull,
    'items-center': !_vm.isMultiline && !_vm.itemsStart,
    'items-start': _vm.itemsStart
  }},[_c('div',{staticClass:"text-gray-500",class:[
      _vm.isFull ? 'flex-grow' : 'w-1/3 sm:w-48 flex-none',
      _vm.itemsStart ? 'pt-2' : ''
    ]},[_vm._v(" "+_vm._s(_vm.label)+" ")]),(!_vm.isMultiline)?_c('div',[(_vm.hasLink)?_c('router-link',{staticClass:"underline text-indigo-500",attrs:{"to":_vm.hasLink,"target":"_blank"}},[_vm._v(_vm._s(_vm.value))]):_c('div',[_vm._v(" "+_vm._s(_vm.value)+" ")])],1):_c('div',[(_vm.isMultilineArray(_vm.value))?_c('div',_vm._l((_vm.value),function(val,index){return _c('div',{key:`line-${index}`},[_vm._v(" "+_vm._s(val)+" ")])}),0):_c('div',[_vm._v(_vm._s(_vm.value))])]),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }