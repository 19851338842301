<template>
  <div>
    <!-- <loading-button secondary> Pause </loading-button> -->
    <div
      class="hover:underline cursor-pointer flex space-x-1"
      @click.prevent="toggleModal"
    >
      <div>
        Charge customer
      </div>
      <div>&rarr;</div>
    </div>

    <modal
      :open="modalVisible"
      :on-close="toggleModal"
      id="charge-customer"
      size="xxs"
      modal-title="Charge customer"
    >
      <div class="space-y-4">
        <div class="">You are about to charge the customer</div>
        <div class="text-center text-4xl font-semibold">
          <div>
            {{
              userProfile.subscription.subscription.settings.finalSubscription
                | formatPounds
            }}
          </div>
        </div>
        <div class="  ">
          This will create a new order and can not be undone. Are you sure?
        </div>
        <div
          v-if="error"
          class=" bg-red-100 p-4 text-red-400 rounded mb-4 error-msg"
        >
          {{ error }}
        </div>

        <input
          v-model="authenticate"
          placeholder="Type CHARGENOW to authenticate"
          type="text"
        />
        <form @submit.prevent="chargeCustomer">
          <loading-button
            :is-loading="isLoading"
            is-full
            warning
            :is-enabled="isValid"
            class="text-center"
            label="Charge customer"
          />
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/common/ui/Modal';
import { subtract } from 'lodash/fp';
import LoadingButton from '@/components/common/ui/LoadingButton';

import { mapGetters } from 'vuex';

export default {
  components: {
    Modal,
    LoadingButton
  },
  data() {
    return {
      authenticate: '',
      modalVisible: false,
      isLoading: false,
      error: null
    };
  },
  computed: {
    isValid() {
      return this.authenticate === 'CHARGENOW' ? true : false;
    },
    ...mapGetters({
      userProfile: 'getCurrentUserProfile'
    }),
    hasCredit() {
      return this.userProfile.user.credit > 0;
    },
    priceCredit() {
      const newCredit = subtract(
        this.userProfile.subscription.subscription.costInt,
        this.userProfile.user.credit
      );
      const val = newCredit / 100 < 0 ? 0 : newCredit / 100;
      return val.toLocaleString(undefined, { minmumSignificantDigits: 2 });
    }
  },
  methods: {
    toggleModal() {
      this.authenticate = '';
      this.modalVisible = !this.modalVisible;
    },
    chargeCustomer() {
      this.isLoading = true;
      this.error = null;
      this.$store
        .dispatch('chargeNow', {
          id: this.$route.params.id,
          data: {
            password: this.password
          }
        })
        .then(response => {
          this.isLoading = false;

          const resp = response.data.content[0];
          if (resp.needs3DS) {
            this.error = 'Needs 3DS authentication, cannot put order through';
          } else {
            const promises = [];
            promises.push(
              this.$store.dispatch('getUser', this.$route.params.id)
            );
            promises.push(
              this.$store.dispatch(
                'getUserCurrentSubscription',
                this.$route.params.id
              )
            );
            promises.push(
              this.$store.dispatch('getUserHistory', this.$route.params.id)
            );
            Promise.all(promises).then(() => {
              this.isLoading = false;
              this.toggleModal();
            });
          }
        })
        .catch(error => {
          this.isLoading = false;
          this.error = error.response.data.error.message;
        });
    }
  }
};
</script>

<style></style>
