<template>
  <div
    class="flex py-1"
    :class="{
      'w-full': isFull,
      'items-center': !isMultiline && !itemsStart,
      'items-start': itemsStart
    }"
  >
    <div
      class="text-gray-500 "
      :class="[
        isFull ? 'flex-grow' : 'w-1/3 sm:w-48 flex-none',
        itemsStart ? 'pt-2' : ''
      ]"
    >
      {{ label }}
    </div>
    <div v-if="!isMultiline">
      <router-link
        class="underline text-indigo-500"
        v-if="hasLink"
        :to="hasLink"
        target="_blank"
        >{{ value }}</router-link
      >
      <div v-else>
        {{ value }}
      </div>
    </div>
    <div v-else>
      <div v-if="isMultilineArray(value)">
        <div v-for="(val, index) in value" :key="`line-${index}`">
          {{ val }}
        </div>
      </div>
      <div v-else>{{ value }}</div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { isArray } from 'lodash/fp';
export default {
  props: {
    label: {
      type: String
    },
    value: {
      type: [String, Array, Number]
    },
    hasLink: {
      type: Object
    },
    isFull: {
      type: Boolean
    },
    multiline: {
      type: Boolean,
      default: false
    },
    itemsStart: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isMultiline() {
      return isArray(this.value) || this.multiline;
    }
  },
  methods: {
    isMultilineArray() {
      return isArray(this.value);
    }
  }
};
</script>

<style lang="css" scoped></style>
