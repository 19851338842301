<template>
  <div v-if="!isHidden">
    <div
      class="flex flex-row py-1"
      :class="isTruncate ? ' items-center' : ' items-start'"
    >
      <div
        class="text-gray-500 text-sm w-20 flex-none"
        :class="isTruncate ? '' : 'py-0.5'"
      >
        {{ getTime(activity.date) }}
      </div>
      <div
        :class="{ truncate: isTruncate, 'is-warning': isWarning }"
        v-html="formattedMessage"
      ></div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { sumBy, capitalize, includes, find, join } from 'lodash/fp';
import { mapGetters } from 'vuex';
import { format } from 'date-fns';

export default {
  props: {
    activity: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      lang: 'langGetter',
      userProfile: 'getCurrentUserProfile',
      isAdmin: 'isAdmin',
      products: 'getProducts',
      isProduction: 'isProductionGetter'
    }),
    formattedMessage() {
      const a = this.activity;
      if (a.type === 'packedbypacker') {
        const type = a.data.dispatch.isSample
          ? `Taster Box (#${a.data.dispatchId}-TST)`
          : `Subscription (#${a.data.dispatchId}-SUB)`;
        return `${type} marked as packed by ${
          a.data.packer ? a.data.packer.name : a.data.packerId
        }`;
      }
      if (a.type === 'shippedbypacker') {
        const type = a.data.dispatch.isSample
          ? `Taster Box (#${a.data.dispatchId}-TST)`
          : `Subscription (#${a.data.dispatchId}-SUB)`;
        return `${type} marked as shipped by ${
          a.data.packer ? a.data.packer.name : a.data.packerId
        } `;
      }
      if (a.type === 'emailsent') {
        return `${
          a.data.tag2
            ? this.lang.emails[a.data.tag + '-' + a.data.tag2]
            : a.data.email
              ? `${this.lang.emails[a.data.tag]} email sent to ${
                  a.data.client
                } <div class='activity-reason mt-2  p-1 px-2.5 leading-relaxed italic flex-grow  font-mono space-y-2'><div class="flex"> ${
                  a.data.email.subject
                } [${a.data.email.template}]</div></div>`
              : `${this.lang.emails[a.data.tag]} email sent to ${a.data.client}`
        }`;
      }

      if (a.type === 'shelterpouchesadded') {
        return `${a.data.pouches} shelter pouches added (${a.data.oldValue}  &rarr; ${a.data.newValue})`;
      }
      if (a.type === 'subscriptionordered') {
        if (a.data.custom) {
          return `Custom Box order <a class="text-indigo-500 hover:underline" target="_blank" href="${this.getOrderLink(
            a.data.orderId
          )}">#${a.data.orderId}</a> created by ${
            a.data.packer ? a.data.packer.name : '?'
          }`;
        }
        return `Started a subscription for ${a.data.displayCost}`;
      }
      if (a.type === 'subscriptionupdated') {
        return `${
          a.data.byPacker
            ? this.toCapitalize(a.data.packer.name)
            : this.toCapitalize(a.data.client)
        } updated ${a.data.byPacker ? 'a users' : 'their'} subscription (${
          a.data.displayCostOld || a.data.oldDisplayCost || '?'
        } &rarr; ${a.data.displayCost})`;
      }
      if (a.type === 'subscriptionrenewed') {
        if (a.data.creditUsed > 0) {
          return `Subscription renewed (<span class="inline-flex space-x-1">
              <span class="line-through text-gray-500">
               ${this.$options.filters.formatPounds(a.data.cost)}
              </span>
              <span>${this.$options.filters.formatPounds(a.data.charged)}</span>
            </span>)`;
        } else {
          return `Subscription renewed (${a.data.displayCost})`;
        }
      }
      if (a.type === 'subscriptionbroughtforward') {
        if (a.data.byPacker) {
          return `${a.data.packer.name} brought subscription forward`;
        }
        return `User brought subscription forward`;
      }
      if (a.type === 'referralcodeused') {
        return `Referral code used for a ${
          a.data.type === 'signup' ? 'Taster Box' : 'Subscription'
        } (<a class="text-indigo-500 hover:underline" target="_blank" href="${this.getLink(
          a.data.type === 'samplebox' ? a.data.otherUserId : a.data.user
        )}">${a.data.type === 'samplebox' ? a.data.otherUserId : a.data.user || '??'}</a>)`;
      }
      if (a.type === 'accountcreated') {
        return `Account created`;
      }
      if (a.type === 'updatedetails') {
        const detailType = a.data.cats ? 'cats' : a.data.type;

        a.data.cats;
        return `${
          a.data.byPacker
            ? a.data.packer.name
            : this.toCapitalize(a.data.client)
        }  updated ${
          a.data.byPacker ? 'the users' : 'their'
        } ${detailType} details`;
      }
      if (a.type === 'creditchanged') {
        return `${a.data.byPacker ? a.data.packer.name : 'System'}  ${
          a.data.creditchange < 0 ? 'removed' : 'added'
        } ${this.creditFormat(a.data.creditchange)} credit (${this.creditChange(
          a.data.balance,
          a.data.creditchange
        )})`;
      }
      if (a.type === 'sampleboxordered') {
        if (a.data.custom) {
          return `Taster Box order <a class="text-indigo-500 hover:underline" target="_blank" href="${this.getOrderLink(
            a.data.orderId
          )}">#${a.data.orderId}</a> created by ${
            a.data.packer ? a.data.packer.name : '?'
          } `;
        }
        return `Ordered a Taster Box`;
      }
      if (a.type === 'autosubscriptioncreated') {
        return `Trial has been created (${a.data.displayCost})`;
      }
      if (a.type === 'subscriptiontrialconversion') {
        return `Trial converted into subscription `;
      }
      if (a.type === 'subscriptioncancelled') {
        return `<div>${
          a.data.byPacker
            ? a.data.packer.name
            : this.toCapitalize(a.data.client)
        } cancelled ${a.data.byPacker ? 'the users' : 'their'} ${
          a.data.cancelOnTrial ? 'trial' : 'subscription'
        }</div> <div class='activity-reason mt-2  p-1 px-2.5 leading-relaxed italic'>${
          a.data.reason || a.data.note
        }</div>`;
      }
      if (a.type === 'extendtrial') {
        const date = a.data.unpause || a.data.date;
        const isNegative = this.isNegativeNumber(a.data.days);
        return `<div>${
          a.data.packer ? a.data.packer.name : this.toCapitalize(a.data.client)
        } ${isNegative ? 'brought forward' : 'extended'} the trial </div> <div class='activity-reason mt-2  p-1 px-2.5 leading-relaxed space-y-2 text-xxs  italic'>${
          a.data.reason
            ? `<div>${a.data.reason ? a.data.reason : 'No reason given'}</div>`
            : ``
        }<div>${isNegative ? 'to' : 'until'} ${date ? this.formatDate(date) : `[?]`} (${a.data.days} days)</div></div>`;
      }
      if (a.type === 'subscriptionpaused') {
        if (a.data.monthly || a.data.paydayMonth) {
          return `<div>${
            a.data.packer
              ? a.data.packer.name
              : this.toCapitalize(a.data.client)
          } skipped a monthly box </div> <div class='activity-reason mt-2  p-1 px-2.5 leading-relaxed space-y-2 text-xxs  italic'>${
            a.data.note
              ? `<div>${a.data.note ? a.data.note : 'No reason given'}</div>`
              : ``
          }<div>until ${a.data.unpause ? this.formatDate(a.data.unpause) : `[?]`} </div></div>`;
        } else {
          return `<div>${
            a.data.packer
              ? a.data.packer.name
              : this.toCapitalize(a.data.client)
          } paused subscription </div> <div class='activity-reason mt-2  p-1 px-2.5 leading-relaxed space-y-2 text-xxs  italic'>${
            a.data.note
              ? `<div>${a.data.note ? a.data.note : 'No reason given'}</div>`
              : ``
          }<div>until ${a.data.unpause ? this.formatDate(a.data.unpause) : `[?]`} (${a.data.days} days)</div></div>`;
        }
      }
      if (a.type === 'cancelledbypacker') {
        return `<div>${
          a.data.packer.name
        } cancelled order #<a class="hover:underline " href="/packing/${
          a.data.dispatchid
        }">${this.getOrderId(
          a.data.dispatchid
        )}</a></div> <div class='activity-reason mt-2  p-1 px-2.5 leading-relaxed  italic'>${
          a.data.reason || a.data.message
        }</div>`;
      }
      if (a.type === 'usernoteadded') {
        return `<div>${a.data.packer.name} ${
          a.data.deleted ? 'removed' : 'added'
        } a note</div> <div class='${
          a.data.deleted ? 'line-through hover:no-underline' : ''
        } activity-reason mt-2  p-1 px-2.5 leading-relaxed  rounded italic'>${
          a.data.deleted ? a.data.note[0].note : a.data.note || a.data.message
        }</div>`;
      }
      if (a.type === 'paymentfailed') {
        return this.isAdmin
          ? `<div>Subscription payment failed. <a class="text-indigo-500 hover:underline" target="_blank" href="${a.data.link}">View</a></div>`
          : 'Subscription payment failed';
      }
      if (a.type === 'enablepayday') {
        return `User set a payment date (${
          a.data.dayofmonth ? this.formatOrdinal(a.data.dayofmonth) : '?'
        } of every month)`;
      }
      if (a.type === 'subscriptionupdateddate') {
        return `${a.data.packer.name} set a payment date (${
          a.data.dom ? this.formatOrdinal(a.data.dom) : '?'
        } of every month)`;
      }
      if (a.type === 'enable30day') {
        return `User reverted to a 28 day rolling subscription`;
      }
      if (a.type === 'giftadded') {
        return ` <div class="  flex-col space-y-2  relative  rounded"><div class='flex items-center space-x-1'> <div>Bonus items were added to this order.</div><span class="text-yellow-400 text-xs">&starf;</span> </div><div class='activity-reason mt-2  p-1 px-2.5 leading-relaxed  rounded italic text-sm '>${this.getItems(
          a.data.bonus
        )}</div></div>`;
      }
      if (a.type === 'paymentattempted') {
        return `<div class="activity-accordion"><div>Payment attempted ${
          a.attempt.data.online && !a.attempt.data.byPacker
            ? 'by the user'
            : 'by the system'
        } for <span class="space-x-1"><span class="font-mono p-1 px-2 rounded text-xs bg-gray-100 text-gray-700 uppercase ">£${this.getPaymentCost(
          a.attempt.data.cost
        )}</span><a target="_blank" href="https://dashboard.stripe.com/${
          !this.isProduction ? 'test/' : ''
        }payments/${
          a.attempt.data.vendorDetails.intent
        }"<span class="font-mono p-1 px-2 rounded text-xs bg-gray-100 text-gray-700 hover:underline uppercase">${
          a.attempt.data.vendorDetails.intent
        }</span></a></span> <span class="p-1 px-2 rounded text-xs leading-none uppercase  font-mono ${
          a.attempt.class
        }">${a.attempt.label}</span> ${
          a.attempt.label === 'Failed'
            ? `<input type="checkbox" class="activity-accordion-input" id="${
                a.attempt.data.vendorDetails.intent
              }" /><span class='flex-inline inline-flex p-1 px-2 rounded text-xs leading-none uppercase bg-gray-100  font-mono '><label class=" text-gray-500 activity-accordion-label" for="${
                a.attempt.data.vendorDetails.intent
              }">...</label></span> ${
                a.attempt.label === 'Failed'
                  ? `<div class="activity-accordion-content is-warning"><div class='activity-reason mt-2  p-1 px-2.5 leading-relaxed   italic space-y-2'><div>${
                      a.attempt.data.message
                    } [${
                      a.attempt.data.vendorDetails.errCode
                    }]</div><div class="text-gray-400 flex space-x-2 items-center text-xs"><span class="w-7 rounded overflow-hidden"><img src="/images/cards/${
                      a.attempt.data.vendorDetails.card.brand
                    }.svg" /></span><span>${this.toCapitalize(
                      a.attempt.data.vendorDetails.card.brand
                    )}</span><span class="text-xs font-normal">••••</span><span>${
                      a.attempt.data.vendorDetails.card.last4
                    }</span></div></div></div>`
                  : ''
              }</div>`
            : ``
        }</div>`;
      }
      if (a.type === 'freesamplesupdated') {
        const samples = a.data.samples.map(s => {
          return `<div class="w-12 aspect-square" data-tippy-content="${this.mapProduct(
            s
          )}"><img src="/images/products/updated/${s}.webp"/></div>`;
        });
        return `<div class='mb-1'>User updated their free samples</div><div class="flex  items-center"> ${samples.join(
          ''
        )}</div>`;
      }
      if (a.type === 'deliverychange') {
        return `User ${
          a.data.newDelivery === 'yodel_48_free'
            ? 'downgraded their delivery (Free)'
            : 'upgraded their delivery (Paid)'
        }`;
      }
      if (a.type === '3dspopped') {
        return `User asked to authenticate via 3DS`;
      }
      if (a.type === 'autopaymentretry') {
        return `${a.data.numberOfTries} / ${
          a.data.totalRetries
        } payment attempts. ${
          a.data.willretrytomorrow
            ? 'Will retry payment tomorrow'
            : 'No more retries scheduled'
        }`;
      }
      if (a.type === 'coupon') {
        return `<span class="mr-1"><svg data-v-81b94850="" data-v-cf0bbf92="" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="ticket-simple" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="text-sm text-indigo-600 svg-inline--fa fa-ticket-simple fa-w-18"><path data-v-81b94850="" data-v-cf0bbf92="" fill="currentColor" d="M576 208C549.5 208 528 229.5 528 256C528 282.5 549.5 304 576 304V384C576 419.3 547.3 448 512 448H64C28.65 448 0 419.3 0 384V304C26.51 304 48 282.5 48 256C48 229.5 26.51 208 0 208V128C0 92.65 28.65 64 64 64H512C547.3 64 576 92.65 576 128V208zM64 96C46.33 96 32 110.3 32 128V182.7C60.25 195 80 223.2 80 256C80 288.8 60.25 316.1 32 329.3V384C32 401.7 46.33 416 64 416H512C529.7 416 544 401.7 544 384V329.3C515.7 316.1 496 288.8 496 256C496 223.2 515.7 195 544 182.7V128C544 110.3 529.7 96 512 96H64z" class=""></path></svg></span> ${
          a.data.coupon.display
        } coupon ${a.data.type === 'delete' ? 'removed' : 'added'} by ${
          a.data.packer ? a.data.packer.name : a.data.packerId
        }`;
      }

      if (a.type === 'dispatchchanged') {
        return `Order <a class="text-indigo-500 hover:underline" target="_blank" href="${this.getOrderLink(
          a.data.id
        )}">#${a.data.id}</a> ${
          a.data.status === 'hold' ? 'put on hold' : 'hold removed'
        } by ${a.data.packer ? a.data.packer.name : a.data.packerId}`;
      }

      return a.type;
    },
    isTruncate() {
      const exclude = [
        'subscriptioncancelled',
        'subscriptionpaused',
        'extendtrial',
        'cancelledbypacker',
        'giftadded',
        'usernoteadded',
        'paymentattempted',
        'freesamplesupdated',
        'emailsent'
      ];
      return !includes(this.activity.type, exclude);
    },
    isWarning() {
      const warning = ['subscriptioncancelled', 'cancelledbypacker'];
      return includes(this.activity.type, warning);
    },
    isHidden() {
      const exclude = ['shelterpouchesadded'];
      if (includes(this.activity.type, exclude)) {
        if (this.activity.data.days === 0) {
          return true;
        }
        return true;
      }
      return false;
    }
  },
  methods: {
    getTime(time) {
      return moment(time).format('h:mm A');
    },
    getItems(items) {
      const arr = [
        { label: 'Pouches', type: 'wet_pouch', items: [] },
        { label: 'Tasty Slices', type: 'wet_tasty', items: [] },
        { label: 'Tasty Chunks', type: 'wet_chunk', items: [] },
        { label: 'Trays', type: 'wet_tray', items: [] },
        { label: 'Cans', type: 'wet_can', items: [] },
        { label: 'Smoothies', type: 'wet_smooth', items: [] }
      ];

      items.forEach(p => {
        const sec = find(['type', p.type], arr);
        sec.items.push(
          `${p.quantity} x ${p.product.name} ${
            p.product.isKitten ? '(Kitten)' : ''
          }`
        );
      });
      const string = [];
      arr.forEach(a => {
        string.push(
          `<div class="text-xs text-gray-400 mt-2 mb-1 italic uppercase">${a.label}</div>`
        );
        a.items.forEach(ai => {
          string.push(`<div>${ai}</div>`);
        });
      });
      return join('', string);
    },
    getLink(id) {
      return `${window.location.origin}/users/${id}/activity`;
    },
    getOrderLink(id) {
      return `${window.location.origin}/packing/${id}`;
    },
    formatOrdinal(val) {
      return moment.localeData().ordinal(val);
    },

    formatDate(date) {
      return format(new Date(date), 'dd MMMM, yyyy');
    },
    toCapitalize(val) {
      return capitalize(val);
    },
    getOrderId(order) {
      return find(['dispatchid', String(order)], this.userProfile.history)
        .invId;
    },
    creditFormat(credit) {
      const c = credit < 0 ? Math.abs(credit) : credit;

      return `£${c.toLocaleString(undefined, {
        minmumSignificantDigits: 2
      })}`;
    },
    creditChange(balance, credit) {
      const before = ((balance - credit * 100) / 100).toFixed(2);

      return `£${before.toLocaleString(undefined, {
        minmumSignificantDigits: 2
      })} &rarr; £${(balance / 100).toFixed(2).toLocaleString(undefined, {
        minmumSignificantDigits: 2
      })}`;
    },
    calculateValue(items) {
      return (
        sumBy(function (o) {
          return o.costInt;
        }, items) / 100
      );
    },
    getPaymentCost(total, credit) {
      return (total - (credit || 0)) / 100;
    },
    mapProduct(sku) {
      // return product name based on sku
      const product = find(['sku', sku], this.products);
      return product ? product.name : sku;
    },
    isNegativeNumber(val) {
      return val < 0;
    }
  }
};
</script>

<style lang="scss">
.activity-reason {
  @apply text-gray-500  border-gray-500 border-l-2 font-mono text-xs;
}
.is-warning {
  .activity-reason {
    @apply text-red-500  border-red-500 border-l-2;
  }
}

input.activity-accordion-input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.activity-accordion {
}
.activity-accordion-label {
  cursor: pointer;
}
.activity-accordion-content {
  max-height: 0;

  display: none;
  transition: all 0.35s;
}
input.activity-accordion-input:checked ~ .activity-accordion-content {
  max-height: 100vh;
  display: block;
  margin-top: 5px;
}
</style>
