<template>
  <div v-if="userProfile">
    <!-- <loading-button secondary> Pause </loading-button> -->
    <div
      class="hover:underline cursor-pointer flex space-x-1"
      @click.prevent="toggleModal"
    >
      <div>Update subscription</div>
      <div>&rarr;</div>
    </div>

    <modal
      :open="modalVisible"
      :on-close="toggleModal"
      id="edit-subscription"
      modal-title="Edit subscription"
    >
      <div class="min-h-96">
        <div class="flex-grow divide-x border rounded flex">
          <div
            v-for="cat in subscriptionCats"
            :key="`cat-${cat.id}`"
            class="w-96 px-4 py-4"
          >
            <div class="font-medium">{{ cat.name }}</div>
            <div v-for="(value, key) in groupedProducts" :key="`cat-${key}`">
              <div
                class="text-gray-500 italic uppercase text-left text-xs mt-4"
              >
                {{ key }}
              </div>
              <div class="divide-y mb-6">
                <div
                  v-for="product in orderTypes(value)"
                  :key="`product-${product.id}`"
                  class="flex items-center py-0.5 justify-between"
                >
                  <product-item
                    :product="product"
                    :key="product.sku"
                    :products="cat.products"
                    :current-cat="cat.id"
                    :totals="getTotals(cat.products)"
                    @updateProducts="updateProducts"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sticky bottom-0 py-6 bg-white">
          <form @submit.prevent="updateSubscription" class="space-y-4">
            <loading-button :is-loading="isLoading">Update</loading-button>
          </form>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/common/ui/Modal';
import LoadingButton from '@/components/common/ui/LoadingButton';
import ProductItem from '@/components/common/ui/ProductItem';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { cloneDeep, find, filter, includes, orderBy } from 'lodash/fp';
import utils from '@/utils';
export default {
  components: {
    Modal,
    LoadingButton,
    ProductItem
  },
  data() {
    return {
      isLoading: false,
      modalVisible: false
    };
  },
  computed: {
    ...mapGetters({
      userProfile: 'getCurrentUserProfile',
      products: 'getProducts'
    }),
    subscriptionCats() {
      return cloneDeep(this.userProfile?.subscription?.subscription?.cats);
    },
    filteredProducts() {
      const products = filter(function (o) {
        return !includes('taster', o.foodtype) && o.foodcategory !== 'misc';
      }, this.products);

      return products.filter(p => !p.discontinued);
    },
    groupedProducts() {
      return utils.groupProducts(this.filteredProducts);
    }
  },
  methods: {
    toggleModal() {
      this.modalVisible = !this.modalVisible;
    },
    orderTypes(value) {
      return orderBy(['name'], ['asc'], value);
    },
    getTotals(val) {
      const totals = {
        wet_pouch: 0,
        wet_tasty: 0,
        wet_chunk: 0,
        wet_tray: 0,
        wet_can: 0,
        wet_smooth: 0,
        dry: 0
      };
      val.forEach(p => {
        const product = find(['sku', p.sku], this.products);
        const amount = p.quantity * product.increments;

        totals[product.foodtype] += amount;
      });
      return totals;
    },
    updateProducts: function (payload) {
      let products = find(['id', payload.id], this.subscriptionCats).products;

      if (find(['sku', payload.sku], products)) {
        find(['sku', payload.sku], products).quantity = payload.quantity;
      } else {
        products.push({ sku: payload.sku, quantity: payload.quantity });
      }

      products = _.pullAllBy(products, [{ quantity: 0 }], 'quantity');
    },
    updateSubscription() {
      this.isLoading = true;

      this.$store
        .dispatch('updateCatSubscription', {
          data: this.subscriptionCats,
          uid: this.$route.params.id,
          delivery:
            this.userProfile?.subscription?.subscription?.currentDeliveryItem
        })
        .then(() => {
          this.$store
            .dispatch('getUserCurrentSubscription', this.$route.params.id)
            .then(() => {
              this.isLoading = false;
              this.toggleModal();
            });
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style></style>
