<template>
  <div :class="{ 'w-full': isFull }" ref="dropdownTemplate">
    <div
      class="flex items-center space-x-2 rounded flex-none cursor-pointer"
      :class="{
        'bg-transparent hover:bg-gray-100': camo,
        'bg-gray-100 hover:bg-gray-200': !camo,
        'py-2 px-3': isInput,
        'py-1  px-3': !isInput,
        'bg-gray-600 text-white hover:bg-gray-700': isActive,
        'bg-indigo-400 text-white hover:bg-indigo-500': isAction,
        'h-full': stretch
      }"
      :id="`dropdownButton-${key}`"
    >
      <div :class="{ 'flex-grow': isFull }"><slot name="button"></slot></div>
      <font-awesome-icon
        v-if="!noDropdown"
        class="text-xs text-gray-400"
        :icon="['fal', 'chevron-down']"
      />
    </div>

    <div :ref="`dropdownButtonContent-${key}`" :class="width">
      <div><slot name="search"></slot></div>
      <div @click="tippyStatus" :style="styleObject">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import utils from '@/utils';
import tippy from 'tippy.js';
export default {
  props: {
    isFull: {
      type: Boolean,
      default: false
    },
    camo: {
      type: Boolean,
      default: false
    },
    isInput: {
      type: Boolean,
      default: false
    },
    clickClose: {
      type: Boolean,
      default: true
    },
    isActive: {
      type: [Object, Boolean, String],
      default: false
    },
    noDropdown: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: 'bottom'
    },
    isAction: {
      type: Boolean,
      default: false
    },
    stretch: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: 'w-auto'
    }
  },
  data() {
    return {
      key: utils.createKey('dropdown-button', 8),
      tippyInstance: null,
      dropdownWidth: null,
      styleObject: {}
    };
  },
  methods: {
    tippyStatus() {
      if (this.clickClose) {
        this.tippyInstance[0].hide();
      }
    },
    closeDropdown() {
      this.tippyInstance[0].hide();
    },
    observeWidth() {
      const self = this;
      const resizeObserver = new ResizeObserver(o => {
        self.dropdownWidth = o[0].target.offsetWidth;
      });
      resizeObserver.observe(this.$refs.dropdownTemplate);
    }
  },
  watch: {
    dropdownWidth(newValue) {
      this.styleObject = {
        width: this.noDropdown ? '' : `${newValue < 200 ? 260 : newValue}px`
      };
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.observeWidth();
    });
    this.tippyInstance = tippy(`#dropdownButton-${this.key}`, {
      arrow: '',
      animation: 'shift-away',
      delay: [0, 0],
      maxWidth: this.dropdownWidth,
      theme: 'light p-0-dropdown overflow-hidden ',
      interactive: true,
      trigger: 'click',
      placement: this.placement,
      hideOnClick: this.clickClose || true,
      allowHTML: true,
      content: this.$refs[`dropdownButtonContent-${this.key}`],
      appendTo: document.body
    });
  }
};
</script>

<style></style>
