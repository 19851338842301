<template>
  <div>
    <div
      class="flex overflow-x-auto sm:overflow-none items-center mt-3 border-b border-gray-200"
      :class="`px-${embedded}`"
    >
      <div
        v-for="tab in tabs"
        :key="tab.name"
        :class="{
          ' border-indigo-500  text-indigo-500 cursor-default hover:text-indigo-500':
            tab.name === activeTab
        }"
        @click="selectTab(tab.name)"
        class="p-2 px-3 border-b-2 border-transparent text-gray-500 cursor-pointer hover:text-gray-700 flex flex-none items-center"
      >
        {{ tab.name | startCase
        }}<span
          v-if="tab.count"
          class="rounded-full px-2 flex ml-2 justify-center text-xs"
          :class="
            tab.name === activeTab
              ? 'bg-indigo-400 text-white'
              : 'bg-gray-200 text-gray-600'
          "
          >{{ tab.count }}</span
        >
      </div>
    </div>

    <div :class="padding ? 'py-6 px-0 sm:px-8' : 'p-0'">
      <transition name="fade" mode="out-in">
        <div :key="activeTab" v-if="activeTab">
          <slot :name="activeTab"></slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    padding: {
      type: Boolean,
      default: true
    },
    embedded: {
      type: String,
      default: '4'
    },
    setUrl: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return { tabs: [], activeTab: null, selectedIndex: 0 };
  },

  mounted() {
    const newTabs = [];
    const tabs = Object.entries(this.$slots);

    tabs.forEach(t => {
      const tab = {};

      tab.name = t[0];
      tab.count = t[1][0].data.attrs.count;
      newTabs.push(tab);
    });

    this.tabs = newTabs;
    this.getActiveTab();
  },

  methods: {
    selectTab(selectedTab) {
      this.activeTab = selectedTab;

      if (this.setUrl) {
        this.$router.push({
          params: {
            tab: selectedTab
          }
        });
      }
      this.$emit('changed', selectedTab);
    },
    getActiveTab() {
      if (this.$route.params.tab) {
        this.activeTab = this.$route.params.tab;
      } else {
        this.activeTab = this.tabs[this.selectedIndex].name;
      }
    }
  }
};
</script>

<style lang="css" scoped></style>
