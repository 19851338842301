<template>
  <div class="divide-y space-y-4">
    <div>
      <div class="font-medium pb-4">User Details</div>
      <key-value label="Name" :value="user.name" />
      <key-value label="Email" :value="user.email" />
      <key-value v-if="isAdmin">
        <div class="flex space-x-2">
          <user-update-details />
        </div>
      </key-value>
    </div>
    <div class="py-4">
      <div class="font-medium pb-4">Address Details</div>
      <key-value
        label="Address"
        :value="[user.addr1, user.addr2, user.addr3, user.postcode]"
      />

      <key-value label="Mobile" :value="user.phone" />
      <key-value v-if="isAdmin">
        <div class="flex space-x-2">
          <user-update-address />
        </div>
      </key-value>
    </div>
    <div>
      <key-value
        class="mt-4"
        label="Joined on"
        :value="user.joined | formatDate('DD MMMM, YYYY')"
      />
      <key-value label="User ID" :value="$route.params.id" />
    </div>
  </div>
</template>

<script>
import KeyValue from '@/components/common/ui/KeyValue';
import UserUpdateAddress from '@/components/common/ui/user/UserUpdateAddress';
import UserUpdateDetails from '@/components/common/ui/user/UserUpdateDetails';
import { mapGetters } from 'vuex';

export default {
  components: {
    KeyValue,
    UserUpdateAddress,
    UserUpdateDetails
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'isAdmin'
    })
  }
};
</script>

<style lang="css" scoped></style>
