<template>
  <div>
    <div
      @click.prevent="toggleModal"
      :class="
        item.currentDetails.packingError || !canRaise
          ? 'pointer-events-none text-gray-300'
          : 'cursor-pointer hover:bg-gray-100'
      "
    >
      <div class="p-3 py-3 flex items-center leading-none space-x-2 ">
        <div class="w-5 flex justify-center">
          <font-awesome-icon
            :icon="['fal', 'flag']"
            :class="
              item.currentDetails.packingError || !canRaise
                ? 'text-gray-300'
                : 'text-red-400'
            "
          />
        </div>
        <div v-if="canRaise && !item.currentDetails.packingError">
          Flag issue
        </div>
        <div v-else>
          {{
            item.currentDetails.packingError
              ? 'Already Submitted'
              : 'Unavailable'
          }}
        </div>
      </div>
    </div>
    <modal
      :open="isVisible"
      :on-close="toggleModal"
      :id="key"
      size="xs"
      modal-title="Flag issue with order..."
    >
      <div class="space-y-8">
        <div class="font-mono" v-if="item.currentDetails.packedBy">
          <key-value label="Order ID"> #{{ item.invId }} </key-value>
          <key-value label="Packed by">
            <div class="flex space-x-2 items-center">
              <avatar size="sm" :email="item.currentDetails.packedBy.email" />

              <div>{{ item.currentDetails.packedBy.name }}</div>
            </div>
          </key-value>
          <key-value label="Date packed">
            {{ item.packedAt | formatDate('DD MMMM, YYYY h:mm a') }}
          </key-value>
        </div>
        <div class="w-full">
          <textarea
            class="max-w-full"
            placeholder="Enter a brief description explaining the issue..."
            v-model="description"
          />
        </div>
        <div class="flex space-x-4">
          <form @submit.prevent="submit" class="space-y-4">
            <loading-button :is-loading="isLoading" :is-enabled="isValid"
              >Submit</loading-button
            >
          </form>
          <form @submit.prevent="toggleModal">
            <loading-button secondary>Cancel</loading-button>
          </form>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/common/ui/Modal';
import LoadingButton from '@/components/common/ui/LoadingButton';
import utils from '@/utils';
import { mapActions } from 'vuex';
import KeyValue from '../KeyValue.vue';
import Avatar from '../Avatar.vue';
export default {
  name: 'TrackOrderErrorBtn',
  components: {
    Modal,
    LoadingButton,
    KeyValue,
    Avatar
  },
  props: {
    item: {
      type: Object,
      default: () => {},
      required: true
    },
    canRaise: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      isVisible: false,
      key: utils.createKey('order-error-track', 6),
      description: '',
      utils
    };
  },
  computed: {
    isValid() {
      return this.description.length > 0;
    }
  },
  methods: {
    ...mapActions({
      packingMistake: 'packingMistake'
    }),
    toggleModal() {
      this.isVisible = !this.isVisible;
    },
    submit() {
      this.isLoading = true;
      const data = {
        deliveryId: this.item.dispatchid,
        data: {
          packerId: this.item.currentDetails.packedBy.id,
          description: this.description
        }
      };

      this.packingMistake(data).then(() => {
        this.toggleModal();
      });
    }
  },
  mounted() {}
};
</script>

<style></style>
