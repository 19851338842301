<template>
  <div>
    <div class="flex flex-col">
      <div class="font-semibold mb-2 text-left flex">
        <div class="flex-grow">Credit</div>
        <div
          v-if="isAdmin"
          @click.prevent="toggleModal"
          class="font-normal text-gray-500 hover:text-gray-900 cursor-pointer"
        >
          Update &rarr;
        </div>
      </div>

      <div class="flex justify-between items-center py-1">
        <div class="text-gray-600">Current Balance</div>
        <div class="">£{{ availableCredit }}</div>
      </div>
    </div>
    <modal
      :open="modalVisible"
      :on-close="toggleModal"
      id="update-credit"
      size="xs"
      :modal-title="`Update Credit`"
    >
      <form @submit.prevent="updateCredit" class="space-y-6 divide-y">
        <div class="space-y-4">
          <div class="flex items-center space-x-2">
            <div class="w-24 text-gray-500">I want to...</div>
            <div>
              <radio-buttons
                :options="['add', 'remove']"
                v-model="creditCalc"
              />
            </div>
          </div>
          <div class="flex items-center space-x-2">
            <div class="w-24"></div>
            <div class="flex flex-col space-y-4">
              <radio-buttons :options="options" v-model="creditType" />

              <div v-if="isCustom" class="flex items-center space-x-2">
                <div class="w-32 relative flex items-center">
                  <div class="text-gray-500 absolute left-2">£</div>
                  <input
                    v-model="customCredit"
                    type="text"
                    class="is-prefix pl-6"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-6 flex items-center">
          <loading-button :is-loading="isLoading" :is-enabled="true">
            Update
          </loading-button>
          <div class="text-right flex-grow font-medium">
            <span class="text-gray-500 font-normal">New Balance</span> £{{
              newBalance
            }}
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Modal from '@/components/common/ui/Modal';
import LoadingButton from '@/components/common/ui/LoadingButton';
import RadioButtons from '@/components/common/ui/RadioButtons';
import { isNumber, add, subtract } from 'lodash/fp';
export default {
  name: 'UserCredit',
  components: {
    Modal,
    LoadingButton,
    RadioButtons
  },
  data() {
    return {
      isLoading: false,
      modalVisible: false,
      customCredit: 0,
      creditType: 5,
      creditCalc: 'add',
      options: [
        { label: '£5', value: 5 },
        { label: '£10', value: 10 },
        { label: '£15', value: 15 },
        { label: 'Custom...', value: 'custom' }
      ]
    };
  },
  computed: {
    ...mapGetters({
      user: 'getCurrentUserProfile',
      isAdmin: 'isAdmin'
    }),
    newBalance() {
      const newCredit =
        this.creditCalc === 'add'
          ? add(this.user.user.credit, this.creditAmount * 100)
          : subtract(this.user.user.credit, this.creditAmount * 100);
      const val = newCredit / 100 < 0 ? 0 : newCredit / 100;
      return val.toLocaleString(undefined, { minmumSignificantDigits: 2 });
    },
    creditAmount() {
      if (isNumber(this.creditType)) {
        return this.creditType;
      } else {
        return Number(this.customCredit);
      }
    },
    isCustom() {
      return !isNumber(this.creditType);
    },
    availableCredit() {
      return (this.user.user.credit / 100).toFixed(2);
    }
  },
  methods: {
    ...mapActions({
      getSubscription: 'getUserCurrentSubscription'
    }),
    toggleModal() {
      if (this.modalVisible) {
        this.creditType = 5;
        this.creditCalc = 'add';
      } else {
        this.$nextTick(() => {
          //   this.$refs.cancelReason.focus();
        });
      }
      this.modalVisible = !this.modalVisible;
    },
    updateCredit() {
      this.isLoading = true;
      const calcType = this.creditCalc === 'add' ? 'addcredit' : 'removecredit';
      this.$store
        .dispatch('updateCredit', {
          userId: this.$route.params.id,
          credit: this.creditAmount,
          type: calcType
        })
        .then(() => {
          this.toggleModal();
          this.getSubscription(this.$route.params.id);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style></style>
