<template>
  <div v-if="links.totalpages != 1" class="flex justify-between py-4">
    <div>{{ currentPage }} of {{ links.totalpages }}</div>
    <div class="flex space-x-4">
      <button
        @click="getPage(links.previouspage)"
        :disabled="isStart"
        data-tippy-content="Previous"
        class="w-8 h-8rounded leading-none focus:outline-none"
        :class="{
          'text-gray-200 pointer-events-none': isStart,
          'hover:bg-gray-100 has-tippy ': !isStart
        }"
      >
        <font-awesome-icon class="text-lg" :icon="['fal', 'arrow-left-long']" />
      </button>
      <button
        tag="div"
        @click="getPage(links.nextpage)"
        :disabled="isEnd"
        data-tippy-content="Next"
        class="w-8 h-8 rounded leading-none focus:outline-none"
        :class="{
          'text-gray-200 pointer-events-none': isEnd,
          'hover:bg-gray-100 has-tippy ': !isEnd
        }"
      >
        <font-awesome-icon
          class="text-lg"
          :icon="['fal', 'arrow-right-long']"
        />
      </button>
    </div>
  </div>
</template>

<script>
import utils from '@/utils';
import http from '@/http';
import tippy from 'tippy.js';
import { mapGetters } from 'vuex';
export default {
  props: {
    links: {
      type: Object
    },
    update: {
      type: String
    }
  },
  computed: {
    currentPage() {
      return this.pagination.current + 1;
    },
    isStart() {
      return this.currentPage === 1;
    },
    isEnd() {
      return this.currentPage === this.links.totalpages;
    },
    ...mapGetters({
      pagination: 'getPagination'
    })
  },
  methods: {
    getPage(link) {
      http
        .get(utils.sanitizeApiUrl(link))
        .then(response => {
          this.$store.commit(this.update, response.data);
          utils.setUrlParameter('page', this.currentPage);
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted() {
    tippy('.has-tippy', {
      placement: 'top',
      arrow: false,
      delay: [400, 0],
      touch: 'hold'
    });
  }
};
</script>

<style lang="css" scoped></style>
