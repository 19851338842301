<template>
  <div>
    <form @submit.prevent="updatePaymentDate" class="space-y-4">
      <div class="flex-col space-y-4 items-stretch">
        <div class="flex items-center space-x-4">
          <div class="relative flex-grow flex items-center">
            <div
              class="pointer-events-none absolute left-0 p-3 pt-3 pr-4 text-lg text-gray-500"
            >
              <font-awesome-icon
                class=" text-2xl "
                :icon="['fal', 'calendar-day']"
              />
            </div>
            <div
              class="pointer-events-none  absolute right-0 mr-12 text-gray-600"
            >
              of each month
            </div>
            <div
              class="pointer-events-none absolute right-0 p-3 pt-3 pr-4 text-lg text-gray-700"
            >
              <font-awesome-icon :icon="['fal', 'chevron-down']" />
            </div>
            <select v-model="paymentDate" class="pl-12 text-base">
              <option
                v-for="index in 28"
                :key="`dom-${index}`"
                :value="index"
                >{{ index | ordinal }}</option
              >
            </select>
          </div>
        </div>
        <div class="rounded bg-orange-200 p-3 px-4 text-center">
          Their next payment will be on
          <div class="font-semibold text-xl py-2">
            {{ nextPayment }}
          </div>
          <div class="text-sm italic">
            then on the {{ paymentDate | ordinal }} of each month thereafter
          </div>
        </div>
        <div class="flex-grow flex flex-col space-y-4">
          <loading-button
            :is-loading="isLoading"
            is-full
            :is-enabled="true"
            class="text-center"
            label="Update"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import LoadingButton from '@/components/common/ui/LoadingButton';
import { mapGetters } from 'vuex';
import { addMonths, setDate, format } from 'date-fns';
export default {
  name: 'ChangePaymentDate',
  components: {
    LoadingButton
  },

  computed: {
    ...mapGetters({
      userProfile: 'getCurrentUserProfile'
    }),
    nextPayment() {
      const day = new Date().getDate();

      if (this.paymentDate <= day) {
        return format(
          addMonths(setDate(new Date(), this.paymentDate), 1),
          'do MMMM, yyyy'
        );
      } else {
        return format(setDate(new Date(), this.paymentDate), 'do MMMM, yyyy');
      }
    }
  },
  data() {
    return {
      paymentDate: 1,
      isLoading: false
    };
  },
  methods: {
    updatePaymentDate() {
      this.isLoading = true;
      this.$store
        .dispatch('updatePaymentDate', {
          uid: this.$route.params.id,
          suid: this.userProfile.subscription.subscription.subscriptionid,
          dom: this.paymentDate
        })
        .then(() => {
          this.$store
            .dispatch('getUserCurrentSubscription', this.$route.params.id)
            .then(() => {
              this.isLoading = false;
              this.$emit('updated');
            })
            .catch(() => {
              this.isLoading = false;
            });
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style></style>
