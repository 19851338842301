<template>
  <div>
    <!-- <loading-button secondary> Pause </loading-button> -->
    <div
      class="hover:underline cursor-pointer flex space-x-1"
      @click.prevent="toggleModal"
    >
      <div>
        {{
          isOnTrial
            ? 'Extend Trial'
            : isOnPayday
              ? 'Change Payment Date'
              : 'Pause subscription'
        }}
      </div>
      <div>&rarr;</div>
    </div>

    <modal
      :open="modalVisible"
      :on-close="toggleModal"
      id="pause-subscription"
      :size="isOnPayday ? 'xxs' : 'sm'"
      :modal-title="`${
        isOnTrial
          ? 'Extend trial'
          : isOnPayday
            ? 'Change Payment Date'
            : 'Pause subscription'
      }`"
    >
      <form
        v-if="!isOnPayday"
        @submit.prevent="pauseSubscription"
        class="space-y-4"
      >
        <div class="flex space-x-4 items-stretch">
          <v-date-picker
            v-model="pauseDate"
            :min-date="minDate"
            color="indigo"
            is-required
          />
          <div class="flex-grow flex flex-col space-y-4">
            <div class="flex-grow">
              <textarea
                v-model="pauseReason"
                ref="pauseReson"
                class="w-full h-full"
                :placeholder="`${
                  isOnTrial
                    ? 'Add a reason for the extension...'
                    : 'Add a reason for this pause...'
                }`"
              ></textarea>
            </div>

            <loading-button
              :is-loading="isLoading"
              is-full
              :is-enabled="isValid"
              class="text-center"
              :label="isOnTrial ? 'Extend trial' : 'Pause subscription'"
            />
          </div>
        </div>
      </form>
      <div v-else>
        <change-payment-date @updated="toggleModal" />
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/common/ui/Modal';
import {
  addMonths,
  setDate,
  format,
  addDays,
  differenceInCalendarDays
} from 'date-fns';
import LoadingButton from '@/components/common/ui/LoadingButton';

import { mapGetters } from 'vuex';
import ChangePaymentDate from '@/components/common/ui/ChangePaymentDate';

export default {
  components: {
    Modal,
    LoadingButton,
    ChangePaymentDate
  },
  data() {
    return {
      pauseDate: '',
      pauseReason: '',
      paymentDate: 1,
      isLoading: false,
      modalVisible: false
    };
  },
  computed: {
    isValid() {
      if (this.pauseReason.length > 0 && this.pauseLength) {
        return true;
      }
      return false;
    },
    ...mapGetters({
      userProfile: 'getCurrentUserProfile'
    }),
    isOnTrial() {
      return this.userProfile.subscription.subscription.onTrial;
    },
    isOnPayday() {
      return this.userProfile.subscription.subscription.payday;
    },
    nextPayment() {
      const day = new Date().getDate();

      if (this.paymentDate <= day) {
        return format(
          addMonths(setDate(new Date(), this.paymentDate), 1),
          'do MMMM, yyyy'
        );
      } else {
        return format(setDate(new Date(), this.paymentDate), 'do MMMM, yyyy');
      }
    },
    minDate() {
      return addDays(new Date(), 1);
    },
    pauseLength() {
      var given = new Date(
        this.userProfile.subscription.subscription.nextbilldatefull
      );
      var current = new Date(this.pauseDate);

      return differenceInCalendarDays(current, given);
    }
  },
  methods: {
    toggleModal() {
      if (!this.modalVisible) {
        this.pauseDate = addDays(
          new Date(this.userProfile.subscription.subscription.nextbilldatefull),
          1
        );
        if (this.isOnPayday) {
          this.paymentDate = new Date(
            this.userProfile.subscription.subscription.nextbilldatefull
          ).getDate();
        }
      }
      this.pauseReason = '';
      this.modalVisible = !this.modalVisible;
    },
    pauseSubscription() {
      this.isLoading = true;
      this.$store
        .dispatch('pauseSubscription', {
          uid: this.$route.params.id,
          reason: `${this.pauseReason}`,
          length: this.pauseLength,
          onTrial: this.isOnTrial
        })
        .then(() => {
          this.$store
            .dispatch('getUserCurrentSubscription', this.$route.params.id)
            .then(() => {
              this.isLoading = false;
              this.toggleModal();
            })
            .catch(() => {
              this.isLoading = false;
            });
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style></style>
