<template>
  <div>
    <div
      class="hover:underline text-red-500 cursor-pointer flex space-x-1"
      @click.prevent="toggleModal"
    >
      <div>Cancel subscription</div>
      <div>&rarr;</div>
    </div>
    <modal
      :open="modalVisible"
      :on-close="toggleModal"
      id="cancel-subscription"
      :modal-title="`Cancel Subscription`"
    >
      <form @submit.prevent="cancelSubscription" class="space-y-4">
        <textarea
          v-model="cancelReason"
          ref="cancelReason"
          class="w-full"
          placeholder="Add a reason for this cancellation..."
        ></textarea>
        <loading-button :is-loading="isLoading" :is-enabled="isValid" warning>
          Cancel subscription</loading-button
        >
      </form>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/common/ui/Modal';
import LoadingButton from '@/components/common/ui/LoadingButton';
import { mapGetters } from 'vuex';
import utils from '@/utils';
export default {
  components: {
    Modal,
    LoadingButton
  },
  data() {
    return {
      cancelReason: '',
      isLoading: false
    };
  },
  computed: {
    ...mapGetters({
      modalVisible: 'getModalVisible'
    }),
    isValid() {
      return this.cancelReason.length > 0;
    }
  },
  methods: {
    toggleModal() {
      if (this.modalVisible) {
        this.cancelReason = '';
      } else {
        this.$nextTick(() => {
          this.$refs.cancelReason.focus();
        });
      }
      utils.toggleModal();
    },
    cancelSubscription() {
      this.isLoading = true;
      this.$store
        .dispatch('cancelSubscription', {
          uid: this.$route.params.id,
          reason: this.cancelReason
        })
        .then(() => {
          this.$store
            .dispatch('getUserCurrentSubscription', this.$route.params.id)
            .then(() => {
              this.isLoading = false;
              this.toggleModal();
            })
            .catch(() => {
              this.isLoading = false;
            });
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style></style>
