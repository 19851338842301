<template>
  <div class="flex flex-none flex-col">
    <div>
      <div class="flex items-center">
        <div
          class="w-10 h-10 flex items-center  justify-between rounded-full bg-green-400 text-white has-tippy"
          :data-tippy-content="
            history.purchasedAt | formatDate('DD MMMM, YYYY h:mm a')
          "
        >
          <font-awesome-icon
            :icon="['fad', 'box-open']"
            class="text-lg mx-auto"
          />
        </div>

        <div
          class="flex-grow h-2 bg-gray-100"
          :class="{ 'bg-green-200': history.packed }"
        ></div>
        <div
          class="w-10 h-10 flex items-center justify-between  rounded-full"
          :class="
            history.packed
              ? 'bg-green-400 text-white has-tippy'
              : 'bg-gray-200 text-gray-400'
          "
          :data-tippy-content="
            history.packedAt | formatDate('DD MMMM, YYYY h:mm a')
          "
        >
          <font-awesome-icon
            :icon="['fad', 'box-check']"
            class="text-lg mx-auto"
          />
        </div>
        <div
          class="flex-grow h-2 bg-gray-100"
          :class="{ 'bg-green-300': history.shipped }"
        ></div>
        <div
          class="w-10 h-10 flex items-center justify-between  rounded-full"
          :class="
            history.shipped
              ? 'bg-green-400 text-white has-tippy'
              : 'bg-gray-200 text-gray-400'
          "
          :data-tippy-content="
            history.shippedAt | formatDate('DD MMMM, YYYY h:mm a')
          "
        >
          <font-awesome-icon
            :icon="['fad', 'truck-ramp-box']"
            class="text-lg mx-auto"
          />
        </div>
      </div>
    </div>
    <div class="flex justify-between text-sm text-gray-400 mt-2">
      <div>Packing</div>
      <div>Packed</div>
      <div>Shipped</div>
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js';
export default {
  props: {
    history: {
      type: Object
    }
  },
  mounted() {
    tippy('.has-tippy', {
      placement: 'top',
      arrow: false,
      delay: [200, 0]
    });
  }
};
</script>

<style lang="css" scoped></style>
