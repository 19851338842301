<template>
  <div>
    <div class="font-medium flex space-x-2 items-center">
      <font-awesome-icon :icon="['fal', 'crystal-ball']" />
      <div>Notes for Future orders</div>
    </div>

    <div v-if="futureNotes.length > 0" class="divide-y mb-4">
      <div
        class="py-4"
        v-for="(note, index) in futureNotes"
        :key="`note-${index}`"
      >
        {{ note.note }}
        <div
          class="flex mt-3  text-sm  italic space-x-2 text-gray-500 items-center "
        >
          <div>
            Added by
            {{ note.packer ? note.packer.name : '-' }}
          </div>
          <div v-if="note" class="italic">
            <font-awesome-icon :icon="['fal', 'crystal-ball']" />
            will be added to the order on
            {{ getNextPaymentDate() }}
          </div>
          <div v-if="isAdmin">&middot;</div>
          <div
            v-if="isAdmin"
            class="cursor-pointer hover:underline "
            @click="removeNote(note, index)"
          >
            <font-awesome-icon :icon="['fal', 'trash-can']" /> Remove
          </div>
        </div>
      </div>
    </div>
    <div v-else class="mb-6 mt-4 text-gray-500 ">
      There currently no future notes for this user
    </div>
    <notes-input
      :endpoint="endpoint"
      placeholder="Say something..."
      @added="getFutureNotes"
      note-type="order"
    />
  </div>
</template>

<script>
import NotesInput from '@/components/common/ui/NotesInput';
import { mapGetters } from 'vuex';
export default {
  components: {
    NotesInput
  },
  data() {
    return {
      futureNotes: []
    };
  },
  computed: {
    ...mapGetters({
      userProfile: 'getCurrentUserProfile',
      isAdmin: 'isAdmin'
    }),
    activeSubscription() {
      return this.userProfile?.subscription.subscription.active;
    },
    endpoint() {
      return `/api/packers/users/${this.$route.params.id}/userfuturenotes`;
    }
  },
  methods: {
    getFutureNotes() {
      this.$store
        .dispatch('getFutureNotes', { uid: this.$route.params.id })
        .then(response => {
          this.futureNotes = response;
        });
    },
    removeNote(note, index) {
      const endpoint = `/api/packers/users/${this.$route.params.id}/userfuturenotes/${index}`;
      this.$store.dispatch('removeNote', { endpoint }).then(() => {
        this.futureNotes.splice(index, 1);
      });
    },
    getNextPaymentDate() {
      return this.activeSubscription
        ? this.$options.filters.formatDate(
            this.userProfile?.subscription.subscription.nextbilldate,
            'DD MMMM, YYYY',
            true
          )
        : '-';
    }
  },
  mounted() {
    this.getFutureNotes();
  }
};
</script>

<style lang="css" scoped></style>
