var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"dropdownTemplate",class:{ 'w-full': _vm.isFull }},[_c('div',{staticClass:"flex items-center space-x-2 rounded flex-none cursor-pointer",class:{
      'bg-transparent hover:bg-gray-100': _vm.camo,
      'bg-gray-100 hover:bg-gray-200': !_vm.camo,
      'py-2 px-3': _vm.isInput,
      'py-1  px-3': !_vm.isInput,
      'bg-gray-600 text-white hover:bg-gray-700': _vm.isActive,
      'bg-indigo-400 text-white hover:bg-indigo-500': _vm.isAction,
      'h-full': _vm.stretch
    },attrs:{"id":`dropdownButton-${_vm.key}`}},[_c('div',{class:{ 'flex-grow': _vm.isFull }},[_vm._t("button")],2),(!_vm.noDropdown)?_c('font-awesome-icon',{staticClass:"text-xs text-gray-400",attrs:{"icon":['fal', 'chevron-down']}}):_vm._e()],1),_c('div',{ref:`dropdownButtonContent-${_vm.key}`,class:_vm.width},[_c('div',[_vm._t("search")],2),_c('div',{style:(_vm.styleObject),on:{"click":_vm.tippyStatus}},[_vm._t("default")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }