<template>
  <div>
    <div>
      <key-value
        label="Subscription ID"
        :value="userProfile.subscription.subscription.subscriptionid"
      />
      <key-value
        label="Type"
        :value="
          !userProfile.subscription.subscription.payday
            ? '28 Day Rolling'
            : 'Fixed Payment Date'
        "
      />
      <key-value
        label="Payment System"
        v-if="activeSubscription"
        :value="
          userProfile.subscription.subscription.newPaymentUser
            ? 'Stripe'
            : 'Stripe (Legacy)'
        "
      />
      <key-value multiline label="Delivery" v-if="activeSubscription">
        <div class="flex space-x-1">
          <div
            v-html="
              getDeliveryOption(
                userProfile.subscription.subscription.deliveryTypeSelected
              )
            "
          ></div>
          <div v-if="hasDeliveryCost" class="text-gray-600">
            -
            <span
              :class="{
                'line-through text-gray-500': userProfile.coupons.other
              }"
              >{{ subscriptionPrices.originalDelivery | formatPounds }}</span
            ><span v-if="userProfile.coupons.other"> £0</span>
          </div>
        </div>
      </key-value>
      <key-value v-if="isAdmin" class="mb-6">
        <div class="flex space-y flex-col-4">
          <user-override-delivery />
        </div>
      </key-value>
      <key-value label="Coupons" multiline v-if="isAdmin" class="mb-6">
        <div class="flex space-y flex-col-4">
          <add-coupon />
        </div>
      </key-value>

      <key-value
        label="Last updated"
        :value="
          userProfile.subscription.subscription.lastchange
            | formatDate('DD MMMM, YYYY h:mm a')
        "
      />
      <key-value
        label="Last payment"
        :value="
          userProfile.subscription.subscription.lastpaymentdate
            | formatDate('DD MMMM, YYYY')
        "
      />
      <key-value label="Next payment" :value="getNextPaymentDate()" />

      <key-value v-if="activeSubscription" multiline>
        <div class="flex flex-col space-y-1">
          <div class="flex space-x-1">
            <div
              v-if="hasCoupon && !userProfile.coupons.onlyDelivery"
              class="mr-1"
            >
              <font-awesome-icon
                :icon="['fal', 'ticket-simple']"
                class="text-sm text-indigo-600"
              />
            </div>
            <div
              :class="{
                'line-through text-gray-500':
                  hasCredit || (hasCoupon && !userProfile.coupons.onlyDelivery)
              }"
            >
              {{ subscriptionPrices.originalSubscription | formatPounds }}
            </div>
            <div
              v-if="
                hasCoupon && !hasCredit && !userProfile.coupons.onlyDelivery
              "
            >
              {{ subscriptionPrices.finalSubscription | formatPounds }}
            </div>
            <div v-if="hasCredit">
              {{ subscriptionPrices.finalSubscription | formatPounds }}
            </div>
          </div>
        </div>
      </key-value>

      <key-value v-if="isAdmin" class="mt-4">
        <div class="flex space-x-2">
          <user-take-payment />
        </div>
      </key-value>
      <key-value v-if="isAdmin">
        <div class="flex space-y flex-col-4">
          <a
            :href="userProfile.user.stripeLink"
            class="text-indigo-500 hover:underline"
            target="_blank"
            >View in stripe &rarr;</a
          >
        </div>
      </key-value>
      <div class="mt-2 py-4">
        <key-value
          v-if="
            isAdmin && activeSubscription && !paydaySubscription && !isOnTrial
          "
        >
          <div class="flex space-x-2">
            <user-set-payment-date />
          </div>
        </key-value>
        <key-value v-if="isAdmin && activeSubscription">
          <div class="flex space-x-2">
            <user-pause-subscription />
          </div>
        </key-value>
        <key-value v-if="isAdmin">
          <div class="flex space-x-2">
            <user-edit-subscription />
          </div>
        </key-value>

        <key-value v-if="isAdmin && activeSubscription">
          <div class="flex space-y flex-col-4">
            <user-cancel-subscription />
          </div>
        </key-value>
      </div>
    </div>
    <div class="mb-4 font-semibold mt-8">
      Cats in subscription ({{
        userProfile.subscription.subscription.cats.length
      }})
    </div>
    <div
      class="flex flex-col overflow-x-auto pb-4 sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4"
    >
      <div
        :key="`cat-${index}`"
        v-for="(cat, index) in subscriptionCats"
        class="w-full sm:w-96 flex-none text-left border rounded group relative pb-12"
      >
        <div class="flex space-x-4 items-center p-4 pb-0">
          <cats-images :cats="[cat]" />
          <div class="flex-grow">
            <div class="flex justify-between items-center">
              <div class="flex items-center space-x-1">
                <div class="font-semibold">{{ cat.name }}</div>
              </div>
              <div
                class="rounded inline-flex self-end p-1 px-2 text-xs font-semibold uppercase"
                :class="
                  cat.active
                    ? 'bg-green-100 text-green-400'
                    : 'bg-gray-100 text-gray-400'
                "
              >
                {{ cat.active ? 'Active' : 'Inactive' }}
              </div>
            </div>
            <div class="text-sm text-gray-400 flex space-x-1">
              <div>{{ cat.gender | startCase }}</div>
              <div v-if="isKitten(cat.type)">(Kitten)</div>
            </div>
          </div>
        </div>
        <tabs :padding="false" embedded="2" :set-url="false">
          <tab key="Subscription" slot="subscription" :selected="true">
            <div class="p-4 pt-2">
              <div
                v-for="(value, key) in groupProducts(cat.products)"
                :key="`cat-${key}`"
                class="flex flex-col w-full pb-3"
              >
                <div
                  class="text-gray-500 italic uppercase text-xs text-left mt-2 mb-2"
                >
                  {{ key }}
                </div>
                <div
                  v-for="product in value"
                  :key="`cat-${product.sku}`"
                  class="flex w-full justify-between"
                >
                  <div>
                    {{ product.name }}
                    <span v-if="product.foodcategory === 'kitten'"
                      >(Kitten)</span
                    >
                  </div>
                  <div>
                    <transition name="fade" mode="out-in">
                      <span
                        class="text-gray-600 ml-2"
                        :key="
                          getQuantity(
                            product.increments,
                            product.quantity,
                            product.foodtype
                          )
                        "
                      >
                        {{
                          getQuantity(
                            product.increments,
                            product.quantity,
                            product.foodtype
                          )
                        }}
                      </span>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
            <div class="absolute bottom-0 py-4 flex px-4" v-if="isAdmin">
              <div class="w-full flex flex-col space-y-1">
                <user-cat-edit
                  :cat="cat"
                  :position="index"
                  :cats="userProfile.subscription.subscription.cats"
                />
                <user-cat-remove
                  v-if="userProfile.subscription.subscription.cats.length > 1"
                  :cat="cat"
                  :position="index"
                  :cats="userProfile.subscription.subscription.cats"
                />
              </div>
            </div>
          </tab>
          <tab key="Recommendations" slot="recommendations">
            <div class="p-4" v-if="cat.recommendations.length > 0">
              <div
                v-for="(recommendation, index) in groupProducts(
                  cat.recommendations
                )"
                :key="`recommendation-${index}`"
                class="flex flex-col space-y-1"
              >
                <div
                  v-for="product in recommendation"
                  :key="`product-${product.sku}`"
                  class="flex items-center space-x-2"
                  :data-tippy-content="product.name"
                >
                  <div class="w-8 h-8">
                    <img
                      class="object-contain"
                      :src="`/images/products/${product.sku}.webp`"
                    />
                  </div>
                  <div>
                    {{ product.name
                    }}<span v-if="product.foodcategory === 'kitten'">
                      (Kitten)</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="p-4 py-12 text-center text-gray-500">
              No recommendations currently
            </div></tab
          ></tabs
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import utils from '@/utils';
import KeyValue from '@/components/common/ui/KeyValue';
import UserCatEdit from './UserCatEdit.vue';
import UserCatRemove from './UserCatRemove.vue';
import UserCancelSubscription from '@/components/common/ui/user/UserCancelSubscription';
import UserPauseSubscription from '@/components/common/ui/user/UserPauseSubscription';
import UserEditSubscription from '@/components/common/ui/user/UserEditSubscription';
import { subtract, isEmpty } from 'lodash/fp';
import UserSetPaymentDate from '@/components/common/ui/user/UserSetPaymentDate';
import UserTakePayment from '@/components/common/ui/user/UserTakePayment';
import UserOverrideDelivery from './UserOverrideDelivery.vue';
import AddCoupon from '../coupons/AddCoupon.vue';
import CatsImages from '../CatsImages.vue';
import Tabs from '../tabs/Tabs.vue';
import Tab from '../tabs/Tab.vue';
import tippy from 'tippy.js';

export default {
  components: {
    KeyValue,
    UserCatEdit,
    UserCatRemove,
    UserCancelSubscription,
    UserPauseSubscription,
    UserEditSubscription,
    UserSetPaymentDate,
    UserTakePayment,
    UserOverrideDelivery,
    AddCoupon,
    CatsImages,
    Tabs,
    Tab
  },
  mounted() {
    if (!this.userProfile.subscription) {
      this.$store
        .dispatch('getUserCurrentSubscription', this.$route.params.id)
        .then(() => {})
        .catch(err => {
          console.log(err);
        });
    }
  },
  methods: {
    groupProducts(products) {
      return utils.groupProducts(products);
    },
    getQuantity(increments, quantity, type) {
      return utils.getQuantity(increments, quantity, type);
    },
    getNextPaymentDate() {
      return this.activeSubscription
        ? this.$options.filters.formatDate(
            this.userProfile.subscription.subscription.nextbilldate,
            'DD MMMM, YYYY',
            true
          )
        : '-';
    },
    isKitten(type) {
      return type === 'kitten';
    },
    getDeliveryOption() {
      return `${
        this.userProfile.subscription.subscription.deliveryType.courier
      } (${
        this.userProfile.subscription.subscription.deliveryTypeIsPaid
          ? 'Paid'
          : 'Free'
      }) ${
        this.userProfile?.subscription?.subscription?.deliveryTypeOverride
          ? '<span class="text-gray-500 font-mono text-sm italic">[Overriden]</span>'
          : ''
      }`;
    }
  },
  computed: {
    ...mapGetters({
      userProfile: 'getCurrentUserProfile',
      isAdmin: 'isAdmin'
    }),
    subscriptionPrices() {
      return this.userProfile.subscription.subscription.settings;
    },
    activeRecommendations() {
      const recommendations =
        this.userProfile?.subscription?.subscription?.recommendations;
      // order recommendations by foodtype
      console.log(recommendations);
      return recommendations;
    },

    formattedSubscriptionCost() {
      const delivery =
        this.userProfile.subscription.subscription.currentDelivery.prices.find(
          o =>
            o.id ===
            this.userProfile.subscription.subscription.currentDeliveryItem
        );
      return utils.getDisplayCost(
        this.userProfile.subscription.subscription.costInt,
        delivery.cost,
        true
      );
    },
    activeSubscription() {
      return this.userProfile.subscription.subscription.active;
    },
    paydaySubscription() {
      return this.userProfile.subscription.subscription.payday;
    },
    isOnTrial() {
      return this.userProfile.subscription.subscription.onTrial;
    },
    hasCredit() {
      return this.userProfile.user.credit > 0;
    },
    hasCoupon() {
      return this.userProfile.subscription.subscription.coupons.length > 0;
    },
    priceCredit() {
      const newCredit = subtract(
        this.userProfile?.subscription?.subscription?.costInt,
        this.userProfile?.user?.credit
      );
      const val = newCredit / 100 < 0 ? 0 : newCredit / 100;

      return val.toLocaleString(undefined, { minmumSignificantDigits: 2 });
    },
    subscriptionCats() {
      // for each cat in the subscription, find the sae cat in userProfile.user.cats and add the image to the cat object
      return this.userProfile.subscription.subscription.cats.map(cat => {
        const userCat = this.userProfile.user.cats.find(
          userCat => userCat.name === cat.name
        );
        return {
          ...cat,
          image: userCat.image
        };
      });
    },
    hasDeliveryCost() {
      if (
        !isEmpty(
          this.userProfile.subscription.subscription?.currentDeliveryItem
        )
      ) {
        const delivery =
          this.userProfile.subscription.subscription.currentDelivery.prices.find(
            o =>
              o.id ===
              this.userProfile.subscription.subscription.currentDeliveryItem
          );

        if (delivery.cost === 0) {
          return false;
        } else {
          return delivery;
        }
      } else {
        return false;
      }
    }
  }
};
</script>

<style lang="css" scoped></style>
