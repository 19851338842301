<template>
  <div @click="generateLabel" class="cursor-pointer hover:bg-gray-100 w-52">
    <div class="p-3 py-3 flex items-center leading-none space-x-2">
      <div class="w-5 flex justify-center">
        <font-awesome-icon
          :icon="['fal', 'file-invoice']"
          class="text-gray-400 text-lg"
        />
      </div>
      <div>View Shipping Label</div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'getInvoiceBtn',
  props: {
    endpoint: {
      type: String,
      required: true
    },
    orderId: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions({
      downloadLabel: 'downloadLabel'
    }),
    generateLabel() {
      this.downloadLabel(this.endpoint)
        .then(response => {
          console.log('download response', response);
          // download the response data as a pdf
          const link = document.createElement('a');
          const blob = new Blob([response.data], { type: 'application/pdf' });

          link.href = window.URL.createObjectURL(blob);
          link.target = '_blank';
          link.click();
        })
        .catch(error => {
          console.log(error.response.data.error.message);
          this.$emit('generate-error', error.response.data.error.message);
        });
    }
  }
};
</script>

<style></style>
