<template>
  <div class="flex w-full items-center">
    <div
      class="flex-grow py-2 pr-4 sm:pr-0"
      :class="{ 'text-gray-500': !product.available && !inSubscription }"
    >
      <div class="flex items-center">
        <span
          class="w-2 h-2 rounded-full mr-4"
          :class="
            !product.available && !inSubscription
              ? 'bg-gray-200'
              : 'bg-green-400'
          "
        ></span>
        {{ product.name }}
        <span class="ml-1" v-if="isKitten">(Kitten)</span>
      </div>
    </div>

    <div class="flex items-center text-sm font-semibold w-28 flex-none">
      <div class="w-full">
        <button
          class="text-center bg-gray-100 font-semibold w-full p-1 px-2 rounded focus:outline-none"
          v-if="count == 0"
          @click="increaseCount()"
          :disabled="checkDisabled"
          :class="
            checkDisabled
              ? 'opacity-50 pointer-events-none'
              : 'hover:bg-gray-200   hover:cursor-pointer'
          "
        >
          + Add to box
        </button>
        <div class="flex items-center w-full" v-else>
          <button
            class="rounded-full flex items-center justify-center w-6 h-6 flex-none text-gray-600 focus:outline-none hover:bg-gray-100"
            @click="decreaseCount()"
          >
            <font-awesome-icon :icon="['fal', 'minus']" />
          </button>
          <div class="text-center flex-grow w-full font-semibold p-1 px-3">
            {{ formatTotal(totalCount) }}
          </div>
          <button
            class="rounded-full flex items-center justify-center w-6 h-6 flex-none text-gray-600 focus:outline-none hover:bg-gray-100"
            @click="increaseCount()"
            :disabled="checkDisabled"
            :class="{ 'opacity-25 pointer-events-none': checkDisabled }"
          >
            <font-awesome-icon :icon="['fal', 'plus']" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { includes } from 'lodash/fp';
import { mapGetters } from 'vuex';
export default {
  props: {
    product: Object,
    products: Array,
    currentCat: String,
    totals: Object
  },
  data() {
    return {
      increments: this.product.increments,
      count: 0,
      startingCount: 0,
      firstCount: false
    };
  },
  computed: {
    totalCount() {
      return this.count * this.increments;
    },
    isAdjust() {
      return this.$route.meta.isSubscribe;
    },
    isKitten() {
      return this.product.foodcategory === 'kitten';
    },

    needToAdd() {
      if (this.totals) {
        const totalWet =
          this.totals['wet_pouch'] +
          this.totals['wet_tray'] +
          this.totals['wet_can'] +
          this.totals['wet_smooth'] +
          this.totals['wet_tasty'] +
          this.totals['wet_chunk'];
        return Math.ceil(totalWet / 15) * 15 - totalWet;
      }
      return 0;
    },
    getPrice() {
      return this.needToAdd === 10 || this.needToAdd - 5 === 0;
    },
    inSubscription() {
      return this.startingCount > 0 ? true : false;
    },
    ...mapGetters({
      unavailableProducts: 'getUnavailableProducts',
      subValidation: 'subValidation'
    }),
    checkDisabled() {
      if (
        (includes('wet', this.product.foodtype) &&
          this.subValidation.isWetMax) ||
        (includes('dry', this.product.foodtype) && this.subValidation.isDryMax)
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    count: function (newValue, oldValue) {
      if (this.firstCount) {
        this.$emit('updateProducts', {
          sku: this.product.sku,
          quantity: newValue,
          id: this.currentCat,
          getPrice: this.checkPrice(newValue, oldValue)
        });
      } else {
        this.firstCount = true;
      }
    },
    currentCat: function () {
      this.setCount();
    }
  },
  methods: {
    checkPrice(newValue, oldValue) {
      if (this.product.foodtype === 'dry') {
        return true;
      } else {
        if (newValue > oldValue) {
          return this.needToAdd == 5;
        } else {
          return this.needToAdd == 10;
        }
      }
    },
    increaseCount: function () {
      this.count++;
    },
    formatTotal: function (amount) {
      if (this.product.foodtype === 'dry') {
        if (amount < 1000) {
          return amount + 'g';
        } else {
          return amount / 1000 + 'kg';
        }
      } else {
        return amount;
      }
    },
    decreaseCount: function () {
      if (!this.count == 0) {
        this.count--;
      }
    },
    setCount: function () {
      const product = _.find(this.products, ['sku', this.product.sku]);
      if (product) {
        this.count = Math.ceil(product.quantity);
        this.startingCount = Math.ceil(product.quantity);
        if (this.count !== product.quantity) {
          this.$emit('updateProducts', {
            sku: product.sku,
            quantity: this.count,
            id: this.currentCat
          });
        }
      } else {
        this.count = 0;
        this.firstCount = true;
        this.startingCount = 0;
      }
    }
  },
  mounted() {
    this.setCount();
  }
};
</script>

<style lang="css" scoped></style>
