<template>
  <div
    class="flex -space-x-4 justify-center hover:space-x-2 default-transition"
  >
    <cat-image
      class="border-2 flex-none flex w-14 h-14 aspect-square rounded-full border-white shadow-lg bg-gray-100"
      v-for="cat in cats"
      :key="cat.id"
      :cat="cat"
      rounded
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CatImage from './CatImage.vue';
export default {
  components: { CatImage },
  name: 'CatsImages',
  props: {
    cats: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      userProfile: 'getCurrentUserProfile'
    })
  },

  mounted() {}
};
</script>

<style></style>
