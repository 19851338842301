<template>
  <div class="flex items-center space-x-4">
    <form @submit.prevent="sendReset">
      <loading-button label="Send reset email" :is-loading="isLoading" />
    </form>
    <transition enter-active-class="miniFadeInLeft" mode="out-in">
      <div
        v-if="showMessage"
        key="action-message"
        class="flex items-center"
        :class="hasError ? 'text-red-500' : 'text-green-500'"
      >
        <font-awesome-icon
          :icon="['fal', hasError ? 'xmark' : 'check']"
          class="text-lg mr-2"
        />{{ hasError ? 'There was an error' : 'Password reset email sent' }}
      </div>
    </transition>
  </div>
</template>

<script>
import http from '@/http';
import LoadingButton from '@/components/common/ui/LoadingButton';
export default {
  components: {
    LoadingButton
  },
  props: {
    email: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      hasError: false,
      showMessage: false
    };
  },
  methods: {
    sendReset() {
      this.isLoading = true;
      http
        .post('/me/password/reset', { email: this.email })
        .then(() => {
          this.isLoading = false;
          this.showMessage = true;
        })
        .catch(() => {
          this.isLoading = false;
          this.hasError = true;
          this.showMessage = true;
          throw Error('Could not send password reset email');
        });
    }
  }
};
</script>

<style lang="css" scoped></style>
