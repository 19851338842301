<template>
  <div class="flex items-center space-x-4">
    <form @submit.prevent="sendReview">
      <loading-button label="Send review email" :is-loading="isLoading" />
    </form>
    <transition enter-active-class="miniFadeInLeft" mode="out-in">
      <div
        v-if="showMessage"
        key="action-message"
        class="flex items-center"
        :class="hasError ? 'text-red-500' : 'text-green-500'"
      >
        <font-awesome-icon
          :icon="['fal', hasError ? 'xmark' : 'check']"
          class="text-lg mr-2"
        />{{ hasError ? 'There was an error' : 'Email review sent' }}
      </div>
    </transition>
  </div>
</template>

<script>
import LoadingButton from '@/components/common/ui/LoadingButton';
import { mapActions } from 'vuex';
export default {
  name: 'UserReviewSend',
  components: {
    LoadingButton
  },

  data() {
    return {
      isLoading: false,
      hasError: false,
      showMessage: false
    };
  },
  methods: {
    ...mapActions({
      sendReviewAction: 'sendReview'
    }),
    sendReview() {
      this.isLoading = true;
      this.sendReviewAction(this.$route.params.id)
        .then(() => {
          this.isLoading = false;
          this.showMessage = true;
        })
        .catch(() => {
          this.isLoading = false;
          this.hasError = true;
          this.showMessage = true;
          throw Error('Could not send review email');
        });
    }
  }
};
</script>

<style lang="css" scoped></style>
