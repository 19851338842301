<template>
  <div>
    <div class="divide-y">
      <history-item
        v-for="item in history"
        :key="
          `${item.dispatchid}-${item.type === 'Subscription' ? 'SUB' : 'TST'}`
        "
        :item="item"
      />
    </div>
  </div>
</template>

<script>
import HistoryItem from './HistoryItem.vue';
export default {
  components: {
    HistoryItem
  },

  props: {
    history: {
      type: Array
    }
  },

  mounted() {
    if (!this.history) {
      this.$store
        .dispatch('getUserHistory', this.$route.params.id)
        .then(() => {});
    }
  }
};
</script>

<style lang="css" scoped></style>
